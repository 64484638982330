import React, {useEffect} from 'react';
import { Outlet, useNavigate } from "react-router-dom"
import useIsAuthenticated from 'react-auth-kit/hooks/useIsAuthenticated'
import { GetAdmin } from '../Users/AuthDecode';
import ErrorPage from '../Error';

const AdminCheck = (props) => {
    const isAuthenticated = useIsAuthenticated();
    let navigate = useNavigate();
    const is_admin = GetAdmin();
  
    useEffect(() => {
        if (!isAuthenticated) {
            navigate("/user/login");
        }
  }, [isAuthenticated,is_admin,navigate]);
    
  if(isAuthenticated && is_admin){
  return (
        <>
        {props.error ? (
            <ErrorPage />
        ) : (
            <> 
            <Outlet> </Outlet>
            </>
        )} 
        </>
        );
    }
    else {
        return(
        <>
            <ErrorPage />
        </>
        )
    }
}

export default AdminCheck;