import * as React from 'react';
import { DataGrid } from '@mui/x-data-grid';

const columns = [
  {field: 'Zip_Code', headerName: 'ZIP Code', width:90, editable: false, sortable: false},
  {field: 'State', headerName: 'State', width:200, editable: false, sortable: false},
  {field: 'Households_1', headerName: 'Median HH Income', width:160, editable: false, sortable: false},
  {field: 'Households_2', headerName: 'Mean HH Income', width:150, editable: false, sortable: false},
  {field: 'Families_Households_1', headerName: 'Total', width:60, editable: false, sortable: false},
  {field: 'Families_Household_2', headerName: 'Median Income', width:130, editable: false, sortable: false},
  {field: 'Families_Household_3', headerName: 'Mean Income', width:130, editable: false, sortable: false},
  {field: 'Married-couple_families_1', headerName: 'Total', width:60, editable: false, sortable: false},
  {field: 'Married-couple_familie_2', headerName: 'Median Income', width:130, editable: false, sortable: false},
  {field: 'Married-couple_familie3', headerName: 'Mean Income', width:130, editable: false, sortable: false},
  {field: 'Nonfamily_households_1', headerName: 'Total', width:80, editable: false, sortable: false},
  {field: 'Nonfamily_household_2', headerName: 'Median Income', width:130, editable: false, sortable: false},
  {field: 'Nonfamily_household3', headerName: 'Mean Income', width:130, editable: false, sortable: false},
  {field: 'Number_of_FAMILY_INCOME_BY_FAMILY_SIZE_1', headerName: 'Female', width:100, editable: false, sortable: false},
  {field: 'Number_of_FAMILY_INCOME_BY_FAMILY_SIZ_2', headerName: 'Male', width:100, editable: false, sortable: false},
  {field: 'Household_Income_distribution_1', headerName: 'Total HH count', width:150, editable: false, sortable: false},
  {field: 'Household_Income_distribution_2', headerName: 'Less than $10,000', width:175, editable: false, sortable: false},
  {field: 'Household_Income_distribution_3', headerName: '$10,000 to $14,999', width:175, editable: false, sortable: false},
  {field: 'Household_Income_distribution_4', headerName: '$15,000 to $19,999', width:175, editable: false, sortable: false},
  {field: 'Household_Income_distribution_5', headerName: '$20,000 to $24,999', width:175, editable: false, sortable: false},
  {field: 'Household_Income_distribution_6', headerName: '$25,000 to $29,999', width:175, editable: false, sortable: false},
  {field: 'Household_Income_distribution_7', headerName: '$30,000 to $34,999', width:175, editable: false, sortable: false},
  {field: 'Household_Income_distribution_8', headerName: '$35,000 to $39,999', width:175, editable: false, sortable: false},
  {field: 'Household_Income_distribution_9', headerName: '$40,000 to $44,999', width:175, editable: false, sortable: false},
  {field: 'Household_Income_distribution_10', headerName: '$45,000 to $49,999', width:175, editable: false, sortable: false},
  {field: 'Household_Income_distribution_11', headerName: '$50,000 to $59,999', width:175, editable: false, sortable: false},
  {field: 'Household_Income_distribution_12', headerName: '$60,000 to $74,999', width:175, editable: false, sortable: false},
  {field: 'Household_Income_distribution_13', headerName: '$75,000 to $99,999', width:175, editable: false, sortable: false},
  {field: 'Household_Income_distribution_14', headerName: '$100,000 to $124,999', width:175, editable: false, sortable: false},
  {field: 'Household_Income_distribution_15', headerName: '$125,000 to $149,999', width:175, editable: false, sortable: false},
  {field: 'Household_Income_distribution_16', headerName: '$150,000 to $199,999', width:175, editable: false, sortable: false},
  {field: 'Household_Income_distribution_17', headerName: '$200,000 or more', width:150, editable: false, sortable: false},
  {field: 'Household_Income_by_Age_1', headerName: 'Age 15-24', width:100, editable: false, sortable: false},
  {field: 'Household_Income_by_Age_2', headerName: 'Age 25-44', width:100, editable: false, sortable: false},
  {field: 'Household_Income_by_Age_3', headerName: 'Age 45-64', width:100, editable: false, sortable: false},
  {field: 'Household_Income_by_Age_4', headerName: 'Age 65+', width:100, editable: false, sortable: false},
  {field: 'Median_HOUSEHOLD_INCOME_BY_AGE_1', headerName: 'Age 15-24', width:100, editable: false, sortable: false},
  {field: 'Median_HOUSEHOLD_INCOME_BY_AGE_2', headerName: 'Age 25-44', width:100, editable: false, sortable: false},
  {field: 'Median_HOUSEHOLD_INCOME_BY_AGE_3', headerName: 'Age 45-64', width:100, editable: false, sortable: false},
  {field: 'Median_HOUSEHOLD_INCOME_BY_AGE_4', headerName: 'Age 65+', width:100, editable: false, sortable: false},
  {field: 'Median_Family_Income_By_Family_Size_1', headerName: 'Female', width:150, editable: false, sortable: false},
  {field: 'Median_Family_Income_By_Family_Size_2', headerName: 'Male', width:150, editable: false, sortable: false},
];

const rows = [
    {
    'id':1,
    'Zip_Code':'20001',
    'State':'District of Columbia',
    "Households_1":'$133,211',
    "Households_2":"$165,650",
    "Families_Households_1":"6,523",
    "Families_Household_2":"$186,278",
    "Families_Household_3":"$222,094",
    "Married-couple_families_1":"4,820",
    "Married-couple_familie_2":"$230,737",
    "Married-couple_familie3":"$-999,999",
    "Nonfamily_households_1":"17,088",
    "Nonfamily_household_2":"$116,802",
    "Nonfamily_household3":"$141,915",
    'Number_of_FAMILY_INCOME_BY_FAMILY_SIZE_1':'1284',
    'Number_of_FAMILY_INCOME_BY_FAMILY_SIZ_2':'419',
    'Household_Income_distribution_1':'23,611',
    'Household_Income_distribution_2':'1,664',
    'Household_Income_distribution_3':'914',
    'Household_Income_distribution_4':'549',
    'Household_Income_distribution_5':'234',
    'Household_Income_distribution_6':'426',
    'Household_Income_distribution_7':'676',
    'Household_Income_distribution_8':'347',
    'Household_Income_distribution_9':'251',
    'Household_Income_distribution_10':'345',
    'Household_Income_distribution_11':'680',
    'Household_Income_distribution_12':'870',
    'Household_Income_distribution_13':'2,060',
    'Household_Income_distribution_14':'2,077',
    'Household_Income_distribution_15':'1,834',
    'Household_Income_distribution_16':'3,576',
    'Household_Income_distribution_17':'7,108',
    'Household_Income_by_Age_1':'1,565',
    'Household_Income_by_Age_2':'13,972',
    'Household_Income_by_Age_3':'5,408',
    'Household_Income_by_Age_4':'2,666',
    'Median_HOUSEHOLD_INCOME_BY_AGE_1':'$44,911',
    'Median_HOUSEHOLD_INCOME_BY_AGE_2':'$163,865',
    'Median_HOUSEHOLD_INCOME_BY_AGE_3':'$128,405',
    'Median_HOUSEHOLD_INCOME_BY_AGE_4':'$35,321',
    'Median_Family_Income_By_Family_Size_1':'$64,223',
    'Median_Family_Income_By_Family_Size_2':'$-999999',
    },{
    'id':2,
    'Zip_Code':'20003',
    'State':'District of Columbia',
    'Households_1':'$155054',
    'Households_2':'$191871',
    'Families_Households_1':'6499',
    'Families_Household_2':'$228987',
    'Families_Household_3':'$272754',
    'Married-couple_families_1':'5540',
    'Married-couple_familie_2':'$250001',
    'Married-couple_familie3':'$-999999',
    'Nonfamily_households_1':'10792',
    'Nonfamily_household_2':'$120000',
    'Nonfamily_household3':'$139660',
    'Number_of_FAMILY_INCOME_BY_FAMILY_SIZE_1':'795',
    'Number_of_FAMILY_INCOME_BY_FAMILY_SIZ_2':'164',
    'Household_Income_distribution_1':'17291',
    'Household_Income_distribution_2':'470',
    'Household_Income_distribution_3':'318',
    'Household_Income_distribution_4':'164',
    'Household_Income_distribution_5':'105',
    'Household_Income_distribution_6':'96',
    'Household_Income_distribution_7':'214',
    'Household_Income_distribution_8':'196',
    'Household_Income_distribution_9':'218',
    'Household_Income_distribution_10':'287',
    'Household_Income_distribution_11':'322',
    'Household_Income_distribution_12':'1003',
    'Household_Income_distribution_13':'1444',
    'Household_Income_distribution_14':'1836',
    'Household_Income_distribution_15':'1612',
    'Household_Income_distribution_16':'2876',
    'Household_Income_distribution_17':'6130',
    'Household_Income_by_Age_1':'719',
    'Household_Income_by_Age_2':'10565',
    'Household_Income_by_Age_3':'3676',
    'Household_Income_by_Age_4':'2331',
    'Median_HOUSEHOLD_INCOME_BY_AGE_1':'$106009',
    'Median_HOUSEHOLD_INCOME_BY_AGE_2':'$167317',
    'Median_HOUSEHOLD_INCOME_BY_AGE_3':'$163824',
    'Median_HOUSEHOLD_INCOME_BY_AGE_4':'$128750',
    'Median_Family_Income_By_Family_Size_1':'$67171',
    'Median_Family_Income_By_Family_Size_2':'$153611',
    },{
    'id':3,
    'Zip_Code':'20004',
    'State':'District of Columbia',
    'Households_1':'$152955',
    'Households_2':'$209333',
    'Families_Households_1':'236',
    'Families_Household_2':'$250001',
    'Families_Household_3':'$297664',
    'Married-couple_families_1':'214',
    'Married-couple_familie_2':'$250001',
    'Married-couple_familie3':'$-999999',
    'Nonfamily_households_1':'1094',
    'Nonfamily_household_2':'$133700',
    'Nonfamily_household3':'$190278',
    'Number_of_FAMILY_INCOME_BY_FAMILY_SIZE_1':'11',
    'Number_of_FAMILY_INCOME_BY_FAMILY_SIZ_2':'11',
    'Household_Income_distribution_1':'1330',
    'Household_Income_distribution_2':'55',
    'Household_Income_distribution_3':'11',
    'Household_Income_distribution_4':'0',
    'Household_Income_distribution_5':'13',
    'Household_Income_distribution_6':'0',
    'Household_Income_distribution_7':'8',
    'Household_Income_distribution_8':'0',
    'Household_Income_distribution_9':'0',
    'Household_Income_distribution_10':'16',
    'Household_Income_distribution_11':'6',
    'Household_Income_distribution_12':'13',
    'Household_Income_distribution_13':'162',
    'Household_Income_distribution_14':'271',
    'Household_Income_distribution_15':'95',
    'Household_Income_distribution_16':'170',
    'Household_Income_distribution_17':'510',
    'Household_Income_by_Age_1':'29',
    'Household_Income_by_Age_2':'632',
    'Household_Income_by_Age_3':'464',
    'Household_Income_by_Age_4':'205',
    'Median_HOUSEHOLD_INCOME_BY_AGE_1':'$46964',
    'Median_HOUSEHOLD_INCOME_BY_AGE_2':'$119333',
    'Median_HOUSEHOLD_INCOME_BY_AGE_3':'$193864',
    'Median_HOUSEHOLD_INCOME_BY_AGE_4':'$250001',
    'Median_Family_Income_By_Family_Size_1':'$-999999',
    'Median_Family_Income_By_Family_Size_2':'$-999999',
    },{
    'id':4,
    'Zip_Code':'20005',
    'State':'District of Columbia',
    'Households_1':'$109147',
    'Households_2':'$157958',
    'Families_Households_1':'1597',
    'Families_Household_2':'$204750',
    'Families_Household_3':'$225035',
    'Married-couple_families_1':'1303',
    'Married-couple_familie_2':'$244509',
    'Married-couple_familie3':'$-999999',
    'Nonfamily_households_1':'6911',
    'Nonfamily_household_2':'$99744',
    'Nonfamily_household3':'$142325',
    'Number_of_FAMILY_INCOME_BY_FAMILY_SIZE_1':'167',
    'Number_of_FAMILY_INCOME_BY_FAMILY_SIZ_2':'127',
    'Household_Income_distribution_1':'8508',
    'Household_Income_distribution_2':'398',
    'Household_Income_distribution_3':'170',
    'Household_Income_distribution_4':'334',
    'Household_Income_distribution_5':'157',
    'Household_Income_distribution_6':'176',
    'Household_Income_distribution_7':'102',
    'Household_Income_distribution_8':'134',
    'Household_Income_distribution_9':'182',
    'Household_Income_distribution_10':'242',
    'Household_Income_distribution_11':'212',
    'Household_Income_distribution_12':'985',
    'Household_Income_distribution_13':'754',
    'Household_Income_distribution_14':'822',
    'Household_Income_distribution_15':'779',
    'Household_Income_distribution_16':'764',
    'Household_Income_distribution_17':'2297',
    'Household_Income_by_Age_1':'528',
    'Household_Income_by_Age_2':'5129',
    'Household_Income_by_Age_3':'1779',
    'Household_Income_by_Age_4':'1072',
    'Median_HOUSEHOLD_INCOME_BY_AGE_1':'$61471',
    'Median_HOUSEHOLD_INCOME_BY_AGE_2':'$136003',
    'Median_HOUSEHOLD_INCOME_BY_AGE_3':'$79676',
    'Median_HOUSEHOLD_INCOME_BY_AGE_4':'$61746',
    'Median_Family_Income_By_Family_Size_1':'$82981',
    'Median_Family_Income_By_Family_Size_2':'$-999999',
    },{
    'id':5,
    'Zip_Code':'20006',
    'State':'District of Columbia',
    'Households_1':'$34352',
    'Households_2':'$59678',
    'Families_Households_1':'68',
    'Families_Household_2':'$-999999',
    'Families_Household_3':'$132746',
    'Married-couple_families_1':'42',
    'Married-couple_familie_2':'$-999999',
    'Married-couple_familie3':'$-999999',
    'Nonfamily_households_1':'667',
    'Nonfamily_household_2':'$34102',
    'Nonfamily_household3':'$52228',
    'Number_of_FAMILY_INCOME_BY_FAMILY_SIZE_1':'7',
    'Number_of_FAMILY_INCOME_BY_FAMILY_SIZ_2':'19',
    'Household_Income_distribution_1':'735',
    'Household_Income_distribution_2':'149',
    'Household_Income_distribution_3':'33',
    'Household_Income_distribution_4':'82',
    'Household_Income_distribution_5':'12',
    'Household_Income_distribution_6':'10',
    'Household_Income_distribution_7':'110',
    'Household_Income_distribution_8':'21',
    'Household_Income_distribution_9':'27',
    'Household_Income_distribution_10':'5',
    'Household_Income_distribution_11':'41',
    'Household_Income_distribution_12':'56',
    'Household_Income_distribution_13':'71',
    'Household_Income_distribution_14':'41',
    'Household_Income_distribution_15':'8',
    'Household_Income_distribution_16':'26',
    'Household_Income_distribution_17':'43',
    'Household_Income_by_Age_1':'217',
    'Household_Income_by_Age_2':'385',
    'Household_Income_by_Age_3':'115',
    'Household_Income_by_Age_4':'18',
    'Median_HOUSEHOLD_INCOME_BY_AGE_1':'$-999999',
    'Median_HOUSEHOLD_INCOME_BY_AGE_2':'$34868',
    'Median_HOUSEHOLD_INCOME_BY_AGE_3':'$73021',
    'Median_HOUSEHOLD_INCOME_BY_AGE_4':'$-999999',
    'Median_Family_Income_By_Family_Size_1':'$-999999',
    'Median_Family_Income_By_Family_Size_2':'$-999999',
    },{
    'id':6,
    'Zip_Code':'20007',
    'State':'District of Columbia',
    'Households_1':'$145048',
    'Households_2':'$240888',
    'Families_Households_1':'4927',
    'Families_Household_2':'$250001',
    'Families_Household_3':'$368448',
    'Married-couple_families_1':'4370',
    'Married-couple_familie_2':'$250001',
    'Married-couple_familie3':'$-999999',
    'Nonfamily_households_1':'6899',
    'Nonfamily_household_2':'$99228',
    'Nonfamily_household3':'$145053',
    'Number_of_FAMILY_INCOME_BY_FAMILY_SIZE_1':'395',
    'Number_of_FAMILY_INCOME_BY_FAMILY_SIZ_2':'162',
    'Household_Income_distribution_1':'11826',
    'Household_Income_distribution_2':'564',
    'Household_Income_distribution_3':'175',
    'Household_Income_distribution_4':'129',
    'Household_Income_distribution_5':'177',
    'Household_Income_distribution_6':'181',
    'Household_Income_distribution_7':'306',
    'Household_Income_distribution_8':'185',
    'Household_Income_distribution_9':'187',
    'Household_Income_distribution_10':'107',
    'Household_Income_distribution_11':'201',
    'Household_Income_distribution_12':'552',
    'Household_Income_distribution_13':'1481',
    'Household_Income_distribution_14':'1102',
    'Household_Income_distribution_15':'679',
    'Household_Income_distribution_16':'1045',
    'Household_Income_distribution_17':'4755',
    'Household_Income_by_Age_1':'563',
    'Household_Income_by_Age_2':'4671',
    'Household_Income_by_Age_3':'3711',
    'Household_Income_by_Age_4':'2881',
    'Median_HOUSEHOLD_INCOME_BY_AGE_1':'$23616',
    'Median_HOUSEHOLD_INCOME_BY_AGE_2':'$136069',
    'Median_HOUSEHOLD_INCOME_BY_AGE_3':'$197917',
    'Median_HOUSEHOLD_INCOME_BY_AGE_4':'$133125',
    'Median_Family_Income_By_Family_Size_1':'$94189',
    'Median_Family_Income_By_Family_Size_2':'$250001',
    },{
    'id':7,
    'Zip_Code':'20008',
    'State':'District of Columbia',
    'Households_1':'$123134',
    'Households_2':'$193521',
    'Families_Households_1':'5982',
    'Families_Household_2':'$224400',
    'Families_Household_3':'$331034',
    'Married-couple_families_1':'5266',
    'Married-couple_familie_2':'$241236',
    'Married-couple_familie3':'$-999999',
    'Nonfamily_households_1':'10931',
    'Nonfamily_household_2':'$89399',
    'Nonfamily_household3':'$116563',
    'Number_of_FAMILY_INCOME_BY_FAMILY_SIZE_1':'413',
    'Number_of_FAMILY_INCOME_BY_FAMILY_SIZ_2':'303',
    'Household_Income_distribution_1':'16913',
    'Household_Income_distribution_2':'702',
    'Household_Income_distribution_3':'448',
    'Household_Income_distribution_4':'302',
    'Household_Income_distribution_5':'299',
    'Household_Income_distribution_6':'122',
    'Household_Income_distribution_7':'323',
    'Household_Income_distribution_8':'394',
    'Household_Income_distribution_9':'88',
    'Household_Income_distribution_10':'385',
    'Household_Income_distribution_11':'626',
    'Household_Income_distribution_12':'1040',
    'Household_Income_distribution_13':'2277',
    'Household_Income_distribution_14':'1555',
    'Household_Income_distribution_15':'1235',
    'Household_Income_distribution_16':'2066',
    'Household_Income_distribution_17':'5051',
    'Household_Income_by_Age_1':'849',
    'Household_Income_by_Age_2':'8012',
    'Household_Income_by_Age_3':'4008',
    'Household_Income_by_Age_4':'4044',
    'Median_HOUSEHOLD_INCOME_BY_AGE_1':'$63750',
    'Median_HOUSEHOLD_INCOME_BY_AGE_2':'$107532',
    'Median_HOUSEHOLD_INCOME_BY_AGE_3':'$181168',
    'Median_HOUSEHOLD_INCOME_BY_AGE_4':'$116250',
    'Median_Family_Income_By_Family_Size_1':'$142557',
    'Median_Family_Income_By_Family_Size_2':'$150408',
    
    'Zip_Code':'20009',
    'State':'District of Columbia',
    'Households_1':'$132374',
    'Households_2':'$179185',
    'Families_Households_1':'7642',
    'Families_Household_2':'$204971',
    'Families_Household_3':'$266239',
    'Married-couple_families_1':'5703',
    'Married-couple_familie_2':'$250001',
    'Married-couple_familie3':'$-999999',
    'Nonfamily_households_1':'20883',
    'Nonfamily_household_2':'$117924',
    'Nonfamily_household3':'$146653',
    'Number_of_FAMILY_INCOME_BY_FAMILY_SIZE_1':'1477',
    'Number_of_FAMILY_INCOME_BY_FAMILY_SIZ_2':'462',
    'Household_Income_distribution_1':'28525',
    'Household_Income_distribution_2':'1282',
    'Household_Income_distribution_3':'538',
    'Household_Income_distribution_4':'626',
    'Household_Income_distribution_5':'425',
    'Household_Income_distribution_6':'213',
    'Household_Income_distribution_7':'474',
    'Household_Income_distribution_8':'449',
    'Household_Income_distribution_9':'311',
    'Household_Income_distribution_10':'365',
    'Household_Income_distribution_11':'966',
    'Household_Income_distribution_12':'1600',
    'Household_Income_distribution_13':'3281',
    'Household_Income_distribution_14':'2774',
    'Household_Income_distribution_15':'2778',
    'Household_Income_distribution_16':'4454',
    'Household_Income_distribution_17':'7989',
    'Household_Income_by_Age_1':'1278',
    'Household_Income_by_Age_2':'17546',
    'Household_Income_by_Age_3':'6833',
    'Household_Income_by_Age_4':'2868',
    'Median_HOUSEHOLD_INCOME_BY_AGE_1':'$94000',
    'Median_HOUSEHOLD_INCOME_BY_AGE_2':'$138592',
    'Median_HOUSEHOLD_INCOME_BY_AGE_3':'$148125',
    'Median_HOUSEHOLD_INCOME_BY_AGE_4':'$75328',
    'Median_Family_Income_By_Family_Size_1':'$45598',
    'Median_Family_Income_By_Family_Size_2':'$51871',
    },{
    'id':8,
    'Zip_Code':'20010',
    'State':'District of Columbia',
    'Households_1':'$106560',
    'Households_2':'$150699',
    'Families_Households_1':'5911',
    'Families_Household_2':'$153965',
    'Families_Household_3':'$199237',
    'Married-couple_families_1':'3873',
    'Married-couple_familie_2':'$222774',
    'Married-couple_familie3':'$-999999',
    'Nonfamily_households_1':'8495',
    'Nonfamily_household_2':'$89923',
    'Nonfamily_household3':'$110034',
    'Number_of_FAMILY_INCOME_BY_FAMILY_SIZE_1':'1491',
    'Number_of_FAMILY_INCOME_BY_FAMILY_SIZ_2':'547',
    'Household_Income_distribution_1':'14406',
    'Household_Income_distribution_2':'861',
    'Household_Income_distribution_3':'654',
    'Household_Income_distribution_4':'233',
    'Household_Income_distribution_5':'193',
    'Household_Income_distribution_6':'190',
    'Household_Income_distribution_7':'245',
    'Household_Income_distribution_8':'245',
    'Household_Income_distribution_9':'195',
    'Household_Income_distribution_10':'318',
    'Household_Income_distribution_11':'699',
    'Household_Income_distribution_12':'1081',
    'Household_Income_distribution_13':'1686',
    'Household_Income_distribution_14':'1576',
    'Household_Income_distribution_15':'999',
    'Household_Income_distribution_16':'1702',
    'Household_Income_distribution_17':'3529',
    'Household_Income_by_Age_1':'588',
    'Household_Income_by_Age_2':'8644',
    'Household_Income_by_Age_3':'3339',
    'Household_Income_by_Age_4':'1835',
    'Median_HOUSEHOLD_INCOME_BY_AGE_1':'$-999999',
    'Median_HOUSEHOLD_INCOME_BY_AGE_2':'$111901',
    'Median_HOUSEHOLD_INCOME_BY_AGE_3':'$132326',
    'Median_HOUSEHOLD_INCOME_BY_AGE_4':'$63644',
    'Median_Family_Income_By_Family_Size_1':'$54238',
    'Median_Family_Income_By_Family_Size_2':'$93045',
    },{
    'id':9,
    'Zip_Code':'20011',
    'State':'District of Columbia',
    'Households_1':'$97327',
    'Households_2':'$137295',
    'Families_Households_1':'14702',
    'Families_Household_2':'$129234',
    'Families_Household_3':'$162555',
    'Married-couple_families_1':'8297',
    'Married-couple_familie_2':'$189259',
    'Married-couple_familie3':'$-999999',
    'Nonfamily_households_1':'12419',
    'Nonfamily_household_2':'$69023',
    'Nonfamily_household3':'$99852',
    'Number_of_FAMILY_INCOME_BY_FAMILY_SIZE_1':'4686',
    'Number_of_FAMILY_INCOME_BY_FAMILY_SIZ_2':'1719',
    'Household_Income_distribution_1':'27121',
    'Household_Income_distribution_2':'1440',
    'Household_Income_distribution_3':'529',
    'Household_Income_distribution_4':'592',
    'Household_Income_distribution_5':'838',
    'Household_Income_distribution_6':'873',
    'Household_Income_distribution_7':'566',
    'Household_Income_distribution_8':'707',
    'Household_Income_distribution_9':'617',
    'Household_Income_distribution_10':'871',
    'Household_Income_distribution_11':'1856',
    'Household_Income_distribution_12':'1936',
    'Household_Income_distribution_13':'2882',
    'Household_Income_distribution_14':'2069',
    'Household_Income_distribution_15':'2081',
    'Household_Income_distribution_16':'2768',
    'Household_Income_distribution_17':'6496',
    'Household_Income_by_Age_1':'474',
    'Household_Income_by_Age_2':'11767',
    'Household_Income_by_Age_3':'8550',
    'Household_Income_by_Age_4':'6330',
    'Median_HOUSEHOLD_INCOME_BY_AGE_1':'$51220',
    'Median_HOUSEHOLD_INCOME_BY_AGE_2':'$119814',
    'Median_HOUSEHOLD_INCOME_BY_AGE_3':'$113333',
    'Median_HOUSEHOLD_INCOME_BY_AGE_4':'$69752',
    'Median_Family_Income_By_Family_Size_1':'$85060',
    'Median_Family_Income_By_Family_Size_2':'$59863',
    }
    
];

const columnGroupingModel = [
    {
    groupId: 'Household Date',
    children: [{ field: 'Households_1' }, { field: 'Households_2' }],
    },{
        groupId: 'Family Households',
        children: [
            {field: 'Families_Households_1'},
            {field: 'Families_Household_2'},
            {field: 'Families_Household_3'},
        ],
    },{
        groupId: 'Married-Couple Families',
        children: [
            {field: 'Married-couple_families_1'},
            {field: 'Married-couple_familie_2'},
            {field: 'Married-couple_familie3'},
        ],
    },{
        groupId: 'Nonfamily Households',
        children: [
            {field: 'Nonfamily_households_1'},
            {field: 'Nonfamily_household_2'},
            {field: 'Nonfamily_household3'},
        ],
    },{
        groupId: 'No Spouse House Holds',
        children: [
            {field: 'Number_of_FAMILY_INCOME_BY_FAMILY_SIZE_1'},
            {field: 'Number_of_FAMILY_INCOME_BY_FAMILY_SIZ_2'},
        ],
    },{
        groupId: 'Household Income Distribution',
        children: [
            {field: 'Household_Income_distribution_2'},
            {field: 'Household_Income_distribution_1'},
            {field: 'Household_Income_distribution_3'},
            {field: 'Household_Income_distribution_4'},
            {field: 'Household_Income_distribution_5'},
            {field: 'Household_Income_distribution_6'},
            {field: 'Household_Income_distribution_7'},
            {field: 'Household_Income_distribution_8'},
            {field: 'Household_Income_distribution_9'},
            {field: 'Household_Income_distribution_10'},
            {field: 'Household_Income_distribution_11'},
            {field: 'Household_Income_distribution_12'},
            {field: 'Household_Income_distribution_13'},
            {field: 'Household_Income_distribution_14'},
            {field: 'Household_Income_distribution_15'},
            {field: 'Household_Income_distribution_16'},
            {field: 'Household_Income_distribution_17'},
        ],
    },{
        groupId: 'Household Income Distribution By Age',
        children: [
            {field: 'Household_Income_by_Age_1'},
            {field: 'Household_Income_by_Age_2'},
            {field: 'Household_Income_by_Age_3'},
            {field: 'Household_Income_by_Age_4'},
        ],
    },{
        groupId: 'Median Household Income By Age',
        children: [
            {field: 'Median_HOUSEHOLD_INCOME_BY_AGE_1'},
            {field: 'Median_HOUSEHOLD_INCOME_BY_AGE_2'},
            {field: 'Median_HOUSEHOLD_INCOME_BY_AGE_3'},
            {field: 'Median_HOUSEHOLD_INCOME_BY_AGE_4'},
        ],
    },{
        groupId: 'Median Single Family Income',
        children: [
            {field: 'Median_Family_Income_By_Family_Size_1'},
            {field: 'Median_Family_Income_By_Family_Size_2'},
        ],
    },
];

export default function BasicGroupingDemo() {
  return (
    <div style={{ height: 500,  background: 'white', width: '100%' }} >
      <DataGrid
        rows={rows}
        columns={columns}
        disableRowSelectionOnClick
        columnGroupingModel={columnGroupingModel}
      />
    </div>
    
  );
}