import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import axios from 'axios';
import useAuthHeader from 'react-auth-kit/hooks/useAuthHeader';
import AdminSidePanel from './AdminSidePanel';
import { useCart } from "react-use-cart";

const AdminViewProducts = () => {
  const authHeader = useAuthHeader();
  const [error, setError] = useState(null);
  const [productDetails, setProductDetails] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const { addItem, getItem } = useCart();

  useEffect(() => {
    const config = {
      headers: {
        Authorization: authHeader
      }
    };

    axios.get(`${process.env.REACT_APP_API_URL}/users/get_terra_grasp_products`, config)
      .then(response => {
        setProductDetails(response.data.data); // Access the nested data array correctly
        setIsLoading(false);
      })
      .catch(error => {
        setError(error.toString());
        setIsLoading(false);
      });
  }, [authHeader]);

  const handleAddToCart = (id) => {
    const itemInCart = getItem(id); // Assuming getItem is defined elsewhere
    let warning = false;
    if (!itemInCart) {
      const product = productDetails.find(item => item.id === id);
      if (product) {
        addItem(product); // Assuming addItem is defined elsewhere
        setOpen(true);
      } else {
        console.error(`Product with id ${id} not found`);
      }
    } else {
      warning = true;
    }
    if (warning) {
      setOpen(true);
    }
  };

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <Container fluid>
      <Row>
        <Col md={8}>
          <h1>Terra Grasp Admin Panel</h1>
          <Card>
            <Card.Header>Product Views</Card.Header>
            <div className="custom-table-container">
              <table className="custom-table">
                <thead>
                  <tr>
                    <th>Index</th>
                    <th>Product Name</th>
                    <th>Price</th>
                    <th>Description</th>
                    <th>Product Year</th>
                    <th>Product Type</th>
                    <th>Image Path</th>
                    <th>ID</th>
                    <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                  {productDetails.map((product, index) => (
                    <tr key={product.id}>

                      <td>{index}</td>
                      <td>{product.product_name}</td>
                      <td>{product.price}</td>
                      <td>{product.description}</td>
                      <td>{product.product_year}</td>
                      <td>{product.product_type}</td>
                      <td>{product.image_path}</td>
                      <td>{product.id}</td>
                      <td>
                        <button onClick={() => handleAddToCart(product.id)} className="add-to-cart-button">
                          Add to Cart
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </Card>
        </Col>
        <Col md={4}>
          <AdminSidePanel />
        </Col>
      </Row>
    </Container>
  );
};

export default AdminViewProducts;


