import React from 'react';
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";


function Mission() {
  return (
    <MKBox
    component="section"
    variant="gradient"
    bgColor="dark"
    position="relative"
    borderRadius="16px"
    py={6}
    px={{ xs: 2, lg: 0 }}
  >
    <Container>
      <Grid container
        justifyContent='center'
        borderRadius="16px"
        sx={{ textAlign: "center" }}
        >
        <Grid item xs={12} md={12} sx={{ mb: 6, textAlign: 'center' }}>
          <MKTypography variant="h3" color="white" sx={{ textAlign: 'center' }}>
            About Us
          </MKTypography>
          <MKTypography variant="body2" color="white" sx={{ textAlign: 'center', marginBottom: '1vh', marginTop: '1vh' }}>
          At TerraGrasp, we understand the challenges businesses face when trying to access and interpret census data. Navigating the complexities of government databases to find relevant, accurate information can be both time-consuming and daunting. That&apos;s where we come in. With years of experience in the data industry, our company specializes in extracting and refining census data to deliver clear, organized, and actionable insights tailored to your specific needs. Our team of seasoned professionals combines expertise with efficiency, ensuring you receive the high-quality data you require to make informed decisions.
              <br></br><br></br>Our journey began with a simple mission: to make census data accessible and understandable for businesses of all sizes. We know the ins and outs of census databases like the back of our hand and have developed streamlined processes to gather and analyze data with precision. Our commitment to accuracy and detail means that you can trust the information we provide to guide your strategic planning and operational adjustments. We take pride in our ability to break down complex data sets into clear, concise reports that directly support your business objectives.
              <br></br><br></br>Flexibility and timely delivery are the cornerstones of our service ethos. We recognize that each client has unique needs and timelines, and we are committed to providing adaptable solutions that fit your schedule. Whether you&apos;re looking for comprehensive national data compilations or targeted regional insights, our team is equipped to deliver results that propel your business forward. We hope you consider TerraGrasp for all your census data needs and experience the difference that professional expertise and customer-focused service can make.
          </MKTypography>
        </Grid>
      </Grid>
    </Container>
  </MKBox>
  
  );
}

export default Mission;
