import React, { useEffect } from 'react';
import { Container, Row, Col, ListGroup, Card } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import useIsAuthenticated from 'react-auth-kit/hooks/useIsAuthenticated'
import AdminSidePanel from './AdminSidePanel';

const AdminPanel = () => {
  const isAuthenticated = useIsAuthenticated();
  let navigate = useNavigate();
  useEffect(() => {
    if (!isAuthenticated) {
            navigate("/user/login");
    }
  }, [isAuthenticated, navigate]);

  if(isAuthenticated){
  return (
    <Container fluid>
      <Row>
        <Col md={8}>
          <h1>Terra Grasp Admin Panel</h1>
          <Card>
            <Card.Header>User Stuff</Card.Header>
            <ListGroup variant="flush">
              <ListGroup.Item>
                AUTHENTICATION AND AUTHORIZATION
                <ListGroup.Item action as={Link} to="/admin/groups">Groups</ListGroup.Item>
                <ListGroup.Item action as={Link} to="/admin/users">Users</ListGroup.Item>
              </ListGroup.Item>
              <ListGroup.Item>
                Products Stripe
                <ListGroup.Item action as={Link} to="/admin/products">Products</ListGroup.Item>
                <ListGroup.Item action as={Link} to="/admin/create-product">Create Product</ListGroup.Item>
                <ListGroup.Item action as={Link} to="/admin/create-product-csv">Create Product CSV</ListGroup.Item>
                <ListGroup.Item action as={Link} to="/admin/stripe_products">Stripe Products</ListGroup.Item>
                <ListGroup.Item action as={Link} to="/admin/stripe_prices">Stripe Prices</ListGroup.Item>
              </ListGroup.Item>
            </ListGroup>
          </Card>
        </Col>
        <Col md={4}>
        <AdminSidePanel /> 
        </Col>
      </Row>
    </Container>
  );
}
else {
    return null; 
}
};

export default AdminPanel;
