import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import StateSVGComponent from 'components/images/StateSVGs';
import Grid from "@mui/material/Grid";
import MKButton from "components/MKButton";



const ButtonTable = (props) => {
    const [selectedValue, setSelectedValue] = useState(null);
    const handleButtonClick = (value) => {
        setSelectedValue(value);
        props.onSelectButton(value);
    };

    return (
    <Grid container spacing={.2} sx={{ display: 'flex', flexWrap: 'wrap' }}>
    
        {props.products.map((product, index) => {
          const value = product.product_name;
        return (
            <Grid item sx={{ flexGrow: 1 }} key={index}>
            <MKButton
                tablekey={index}
                variant="gradient"
                color={selectedValue === value ? "info" : "secondary"}
                sx={{ height: "10%",
                    padding: '10px 20px',
                    '& .MuiButton-label': { padding: '10px 30px'}
                }}
                onClick={() => handleButtonClick(value)}
            >
                <StateSVGComponent stateName={value} size={25} color={"white"}/>
                {value}
            </MKButton>
            </Grid>
            );
        })}
    </Grid>
    );
};

export default ButtonTable;
