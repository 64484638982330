import PropTypes from "prop-types";
import Icon from "@mui/material/Icon";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";

function DataTypeDisplay({ flip, title, icon, content }) {
  return (
    <Grid
      container
      direction={{ xs: "column", lg: flip ? "row-reverse" : "row" }}
      alignItems="center"
      py={{ xs: 2 }}
    >

      <Grid item xs={12} lg={1}
        sx={{
          order: { xs: 2, lg: 1 },
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          mt: { xs: 2, lg: 0 },
        }}
      >
        <MKBox
          width="3rem"
          height="3rem"
          minWidth="3rem"
          minHeight="3rem"
          variant="gradient"
          bgColor="info"
          color="white"
          coloredShadow="info"
          display="flex"
          alignItems="center"
          justifyContent="center"
          borderRadius="xl"
        >
          <Icon fontSize="medium">{icon}</Icon>
        </MKBox>
      </Grid>
      <Grid item xs={12} lg={3}
        sx={{
          order: { xs: 1, lg: flip ? 2 : 1 },
          textAlign: "left",
        }}
      >
        <MKTypography variant="h4" my={1}>
          {title}
        </MKTypography>
      </Grid>

      <Grid item xs={12} lg={8}
        sx={{
          order: { xs: 3, lg: 3 },
          mt: { xs: 2, lg: 0 },
          textAlign: "left",
        }}
      >
        <MKTypography variant="body2" color="dark" pl={2}>
          {content}
        </MKTypography>
      </Grid>
    </Grid>
  );
}

// Typechecking props for the DataTypeDisplay
DataTypeDisplay.propTypes = {
  flip: PropTypes.bool,
  icon: PropTypes.string.isRequired,
  content: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
};

export default DataTypeDisplay;
