import React, { useEffect, useState } from 'react';
import axios from 'axios';

function Sitemap() {
  const [sitemap, setSitemap] = useState('');

  useEffect(() => {
    const fetchSitemap = async () => {
      try {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/utils/sitemap.xml`);
        setSitemap(response.data);
      } catch (error) {
        console.error('Error fetching the sitemap:', error);
      }
    };

    fetchSitemap();
  }, []);

  return (
    <pre>{sitemap}</pre>
  );
}

export default Sitemap;
