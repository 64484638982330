import React, { useState } from 'react';
import axios from 'axios';
import { Form, Button, Alert, Row, Col, Container } from 'react-bootstrap';
import useAuthHeader from 'react-auth-kit/hooks/useAuthHeader'
import Papa from 'papaparse';
import AdminSidePanel from './AdminSidePanel';

const AdminCreateProductCSVUpload = () => {
  const [file, setFile] = useState(null);
  const [showAlert, setShowAlert] = useState(false);
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const authHeader = useAuthHeader();

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  
    if (file) {
      Papa.parse(file, {
        header: true,
        complete: function(results) {
          results.data.forEach((product) => {
            // Convert price and product_year to integers, and status to boolean
            product.price = product.price ? parseInt(product.price, 10) : 0;
            product.product_year = product.product_year ? parseInt(product.product_year, 10) : 0;
            product.status =  product.status === 'true' || product.status === true; 
  
            submitProduct(product);
          });
        }
      });
    }
  };
  

  const submitProduct = (product) => {
    const config = {
      headers: {
        Authorization: authHeader
      }
    };

    axios.post(`${process.env.REACT_APP_API_URL}/products/`, product, config)
      .then(response => {
        setShowAlert(true);
        setShowErrorAlert(false);
      })
      .catch(error => {
        setShowAlert(false);
        setShowErrorAlert(true);
        setAlertMessage(error.response.data);
      });
  };

  return (
    <Container fluid>
      <Row>
        <Col md={8}>
          <h1>Terra Grasp Admin Panel</h1>
          <h1>Create Product</h1>
          {showAlert && (
            <Alert variant="success" onClose={() => setShowAlert(false)} dismissible>
              Product(s) successfully created!
            </Alert>
          )}
          {showErrorAlert && (
            <Alert variant="danger" onClose={() => setShowErrorAlert(false)} dismissible>
              {alertMessage}
            </Alert>
          )}
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="file">
              <Form.Label>CSV File</Form.Label>
              <Form.Control
                type="file"
                name="file"
                onChange={handleFileChange}
                required
              />
            </Form.Group>

            <Row>
              <Col md={6}>
                <Button variant="primary" type="submit">
                  Upload and Submit
                </Button>
              </Col>
              <Col md={6}>
                {/* <Link to="/admin/panel"> */}
                  <Button variant="link">
                    Admin Panel
                  </Button>
                {/* </Link> */}
              </Col>
            </Row>
          </Form>
        </Col>
        <Col md={4}>
          <AdminSidePanel />
        </Col>
      </Row>
    </Container>
  );
};

export default AdminCreateProductCSVUpload;

