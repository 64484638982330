import React from 'react';
import { Card, ListGroup } from 'react-bootstrap';
import { Link } from 'react-router-dom';
const AdminSidePanel = () => {
  return (
        <>
          <h2>Welcome</h2>
          <Card>
            <Card.Header>Admin Navigation</Card.Header>
            <Card.Body>
                <ListGroup.Item action as={Link} to="/admin/panel">Admin Panel</ListGroup.Item>
                <ListGroup.Item action as={Link} to="/admin/groups">Groups</ListGroup.Item>
                <ListGroup.Item action as={Link} to="/admin/users">Users</ListGroup.Item>
                <ListGroup.Item action as={Link} to="/admin/comments">comments</ListGroup.Item>
                <ListGroup.Item action as={Link} to="/admin/Products">Products</ListGroup.Item>
                <ListGroup.Item action as={Link} to="/admin/create-product">Create Product</ListGroup.Item>
                <ListGroup.Item action as={Link} to="/admin/create-product-csv">Create Product CSV</ListGroup.Item>
                <ListGroup.Item action as={Link} to="/admin/stripe_products">Stripe Product</ListGroup.Item>
                <ListGroup.Item action as={Link} to="/admin/stripe_prices">Stripe Prices</ListGroup.Item>
            </Card.Body>
          </Card>
        </>
  );
};

export default AdminSidePanel;