/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import React, { useEffect, useRef } from "react";
import Typed from "typed.js";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";
import { Link } from "react-router-dom";

import Products from "./sections/Products";

import bgImage from "assets/images/usa.webp";
import Newsletter from "./sections/Newsletter";

function CustomLanding() {
  const typedJSRef = useRef(null);

    useEffect(() => {
      const typedJS = new Typed(typedJSRef.current, {
        strings: [" Or All States"],
        typeSpeed: 90,
        backSpeed: 90,
        backDelay: 400,
        startDelay: 500,
        loop: true,
      });

      return () => typedJS.destroy();
    }, []);
  return (
    <>
    <MKBox
        minHeight="75vh"
        width="100%"
        sx={{
        backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
                rgba(gradients.dark.main, 0.5),
                rgba(gradients.dark.state, 0.5)
            )}, url(${bgImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        display: "grid",
        placeItems: "center",
        }}
    >
        
        <Container >
        <Grid
            container
            item
            xs={12}
            md={7}
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            sx={{ mx: "auto", textAlign: "center" }}
        >
            <MKTypography
                variant="h1"
                color="white"
                sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                    fontSize: size["3xl"],
                },
            })}
            >
                Get Income Data By Zip Code for Any State.. <span ref={typedJSRef} />
            </MKTypography>
            <Stack direction="row" spacing={1} mt={6} mb={3}>
                <MKButton variant="gradient" color="warning" component={Link} to='/income-by-zipcode'>
                    Get Data
                </MKButton>
                <MKButton variant="text" color="white" component={Link} to='/about-us'>
                    read more
                </MKButton>
            </Stack>
          </Grid>
        </Container>
      </MKBox>
      <Card
        sx={{
            p: 1,
            mx: 'auto',  // Center the card
            mt: -8,
            mb: 4,
            maxWidth: '120vh',  // Adjust this value to match the header's width
            // backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
            // backdropFilter: "saturate(200%) blur(30px)",
            // boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <Products />

        <Newsletter text={"Empower your decisions with data! Subscribe now to get exclusive access to cutting-edge census insights and analytics. Don't miss out on the knowledge that shapes the future."}/>
       
      </Card>
      <MKBox pt={6} px={1} mt={6}>
      </MKBox>
    </>
  );
}

export default CustomLanding;
