import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { EyeSlashFill, EyeFill } from 'react-bootstrap-icons';
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

const PasswordReset = () => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordsMatch, setPasswordsMatch] = useState(true);
  const [passwordsCriteria, setPasswordsCriteria] = useState(true);
  const [message, setMessage] = useState('');
  const [messageCriteria, setMessageCriteria] = useState([]);
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  let { email, password_reset_token } = useParams();

  function validatePassword(password) {
    const results = {
      isValid: true,
      messages: [],
    };
  
    if (password.length < 8) {
      results.isValid = false;
      results.messages.push('Password must be at least 8 characters long.');
    }
    if (!/\d/.test(password)) {
      results.isValid = false;
      results.messages.push('Password must include at least one number.');
    }
    if (!/[A-Z]/.test(password)) {
      results.isValid = false;
      results.messages.push('Password must include at least one uppercase letter.');
    }
  
    return results;
  }

  const updatePassword = async () => {
    if (password !== confirmPassword) {
      setPasswordsMatch(false);
      setMessage('Passwords do not match.');
    } else {
      setPasswordsMatch(true);
    }

    const newPassword = validatePassword(password);
    if (!newPassword.isValid) {
      setPasswordsCriteria(false);
      setMessageCriteria(newPassword.messages);
    } else {
      setPasswordsCriteria(true);
    }

    if (password !== confirmPassword || !newPassword.isValid){
      return;
    }

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/reset-password/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, token: password_reset_token, new_password: password }),
      });
      if (response.status === 200) {
            navigate('/user/login');
      } else {
        setPasswordsMatch(false);
        setMessage('Failed to update password. Please try again.');
      }
    } catch (error) {
      setPasswordsMatch(false);
      setMessage('An error occurred while updating the password.');
    }
  };

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };
  

  return (
    <MKBox height="60vh" display="flex" justifyContent="center" alignItems="center">
      <MKBox width="100%" mx="auto" pt={5}>
        <Grid container spacing={1} justifyContent="center">
          <Grid item xs={11} sm={9} md={5} lg={4} xl={3}>
            <Card>
              <MKBox
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                mx={2}
                mt={-3}
                pt={2.5}
                pb={2.875}
                px={2.5}
                textAlign="center"
                lineHeight={1}
              >
                <MKTypography variant="h4" fontWeight="medium" color="white" mt={1}>
                  Reset Your Password
                </MKTypography>
              </MKBox>
              <MKBox p={3}>
                <MKBox component="form" role="form">
                  <MKBox mb={2}>
                  <MKInput 
                    type={showPassword ? 'text' : 'password'}
                    label="Enter new password"
                    name="password"
                    sx={{ width: '85%' }}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                  />
                  <MKButton
                    onClick={toggleShowPassword}
                    iconOnly
                  >
                    {showPassword ? <EyeFill size={100}/> : <EyeSlashFill size={100}/>}
                  </MKButton>
                  </MKBox>
                  <MKBox mb={2}>
                    <MKInput 
                      type={showPassword ? 'text' : 'password'}
                      label="Confirm new password"
                      name="confirmPassword"
                      sx={{ width: '85%' }}
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      required
                    />
                    <MKButton
                          onClick={toggleShowPassword}
                          iconOnly
                        >
                          {showPassword ? <EyeFill size={100}/> : <EyeSlashFill size={100}/>}
                    </MKButton>
                  </MKBox>
                  {!passwordsMatch && (
                    <MKBox mb={2}>
                      <MKTypography variant="button" color="error">
                        {message}
                      </MKTypography>
                    </MKBox>
                  )}
                  {!passwordsCriteria && messageCriteria.map((msg, index) => (
                    <MKBox key={index} mb={2}>
                      <MKTypography variant="button" color="error">
                        {msg}
                      </MKTypography>
                    </MKBox>
                  ))}
                  <MKBox mt={2} mb={1}>
                    <MKButton variant="gradient" color="info" fullWidth onClick={updatePassword}>
                      Update Password
                    </MKButton>
                  </MKBox>
                </MKBox>
              </MKBox>
            </Card>
          </Grid>
        </Grid>
      </MKBox>
    </MKBox>
  );
};

export default PasswordReset;
                      
