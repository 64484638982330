import React, { useState } from 'react';
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";
import macbook from "assets/images/computer_inbox.webp";
import axios from 'axios'

function Newsletter(props) {
  const [missingData, setMissingData] = useState(false); 
  const [submitted, setSubmitted] = useState(false);
  function getCurrentDate() {
    const today = new Date();
    const year = today.getFullYear();
    const month = today.getMonth() + 1;  // Add 1 because months are zero-indexed.
    const day = today.getDate();
  
    // Ensure month and day are two digits (e.g., 04, 09)
    const formattedMonth = month < 10 ? `0${month}` : month;
    const formattedDay = day < 10 ? `0${day}` : day;
  
    return `${year}-${formattedMonth}-${formattedDay}`;
  }

  const [formData, setFormData] = useState({
    email: '',
    is_subscribed: true,
    date_signed_up: getCurrentDate(),
  });
  const handleChange = (event) => {
    const { name, value } = event.target;
    setMissingData(false);

    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };
  const handleSubmit = (event) => {
    event.preventDefault();

    if (!formData.email) {
      setMissingData(true);
      return;
    }
    axios.post(`${process.env.REACT_APP_API_URL}/requests/subscribe`, formData)
      .then((res) => {
        setMissingData(false);
        setSubmitted(true);
      })
      .catch(error => {
        setMissingData(false);
      });
  };


  return (
    <>
    {submitted ? (
      <SuccessMessage />
    ) : (
      <SubscribeUser 
        formData={formData} 
        handleChange={handleChange} 
        handleSubmit={handleSubmit}
        missingData={missingData}
        text = {props.text}
      />
    )}
  </>
  );
};

const SubscribeUser = ({text,formData, handleChange, handleSubmit, missingData}) => (
  <MKBox component="section" pt={6} my={6}>
  <Container>
    <Grid container alignItems="center">
      <Grid item xs={12} md={6} sx={{ ml: { xs: 0, lg: 3 }, mb: { xs: 12, md: 0 } }}>
        <MKTypography variant="h4">Be the first to see the news</MKTypography>
        <MKTypography variant="body2" color="text" mb={3}>
          {text}
        </MKTypography>
        <Grid container spacing={1}>
          <Grid item xs={8}>
            <MKInput 
                type="email"
                label="Email Here..."
                name="email"
                fullWidth
                value={formData.email}
                onChange={handleChange}
                required />
          </Grid>
          <Grid item xs={4}>
            <MKButton variant="gradient" color="info" sx={{ height: "100%" }} onClick={handleSubmit}>
              Subscribe
            </MKButton>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={5} sx={{ ml: "auto"}}>
        <MKBox position="relative">
          <MKBox component="img" src={macbook} alt="macbook" width="80%" sx={{borderRadius: '24px'}} />
        </MKBox>
      </Grid>
    </Grid>
  </Container>
</MKBox>
)

const SuccessMessage = () => (
  <MKBox component="section" pt={6} my={6}>
  <Container>
    <Grid container alignItems="center">
      <Grid item xs={12} md={6} sx={{ ml: { xs: 0, lg: 3 }, mb: { xs: 12, md: 0 } }}>
        <MKTypography variant="h4">Successfully Signed Up!</MKTypography>
      </Grid>
      <Grid item xs={12} md={5} sx={{ ml: "auto"}}>
        <MKBox position="relative">
          <MKBox component="img" src={macbook} alt="macbook" width="80%" sx={{borderRadius: '24px'}} />
        </MKBox>
      </Grid>
    </Grid>
  </Container>
</MKBox>
)


export default Newsletter;
