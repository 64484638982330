import React from 'react';
import { ReactComponent as AK }  from "../../assets/images/state_svg/AK.svg"
import { ReactComponent as AL }  from "../../assets/images/state_svg/AL.svg"
import { ReactComponent as AR }  from "../../assets/images/state_svg/AR.svg"
import { ReactComponent as AZ }  from "../../assets/images/state_svg/AZ.svg"
import { ReactComponent as CA }  from "../../assets/images/state_svg/CA.svg"
import { ReactComponent as CO }  from "../../assets/images/state_svg/CO.svg"
import { ReactComponent as CT }  from "../../assets/images/state_svg/CT.svg"
import { ReactComponent as DC }  from "../../assets/images/state_svg/DC.svg"
import { ReactComponent as DE }  from "../../assets/images/state_svg/DE.svg"
import { ReactComponent as FL }  from "../../assets/images/state_svg/FL.svg"
import { ReactComponent as GA }  from "../../assets/images/state_svg/GA.svg"
import { ReactComponent as HI }  from "../../assets/images/state_svg/HI.svg"
import { ReactComponent as IA }  from "../../assets/images/state_svg/IA.svg"
import { ReactComponent as ID }  from "../../assets/images/state_svg/ID.svg"
import { ReactComponent as IL }  from "../../assets/images/state_svg/IL.svg"
import { ReactComponent as IN }  from "../../assets/images/state_svg/IN.svg"
import { ReactComponent as KS }  from "../../assets/images/state_svg/KS.svg"
import { ReactComponent as KY }  from "../../assets/images/state_svg/KY.svg"
import { ReactComponent as LA }  from "../../assets/images/state_svg/LA.svg"
import { ReactComponent as MA }  from "../../assets/images/state_svg/MA.svg"
import { ReactComponent as MD }  from "../../assets/images/state_svg/MD.svg"
import { ReactComponent as ME }  from "../../assets/images/state_svg/ME.svg"
import { ReactComponent as MI }  from "../../assets/images/state_svg/MI.svg"
import { ReactComponent as MN }  from "../../assets/images/state_svg/MN.svg"
import { ReactComponent as MO }  from "../../assets/images/state_svg/MO.svg"
import { ReactComponent as MS }  from "../../assets/images/state_svg/MS.svg"
import { ReactComponent as MT }  from "../../assets/images/state_svg/MT.svg"
import { ReactComponent as NC }  from "../../assets/images/state_svg/NC.svg"
import { ReactComponent as ND }  from "../../assets/images/state_svg/ND.svg"
import { ReactComponent as NE }  from "../../assets/images/state_svg/NE.svg"
import { ReactComponent as NH }  from "../../assets/images/state_svg/NH.svg"
import { ReactComponent as NJ }  from "../../assets/images/state_svg/NJ.svg"
import { ReactComponent as NM }  from "../../assets/images/state_svg/NM.svg"
import { ReactComponent as NV }  from "../../assets/images/state_svg/NV.svg"
import { ReactComponent as NY }  from "../../assets/images/state_svg/NY.svg"
import { ReactComponent as OH }  from "../../assets/images/state_svg/OH.svg"
import { ReactComponent as OK }  from "../../assets/images/state_svg/OK.svg"
import { ReactComponent as OR }  from "../../assets/images/state_svg/OR.svg"
import { ReactComponent as PA }  from "../../assets/images/state_svg/PA.svg"
import { ReactComponent as RI }  from "../../assets/images/state_svg/RI.svg"
import { ReactComponent as SC }  from "../../assets/images/state_svg/SC.svg"
import { ReactComponent as SD }  from "../../assets/images/state_svg/SD.svg"
import { ReactComponent as TN }  from "../../assets/images/state_svg/TN.svg"
import { ReactComponent as TX }  from "../../assets/images/state_svg/TX.svg"
import { ReactComponent as USA }  from "../../assets/images/state_svg/USA.svg"
import { ReactComponent as UT }  from "../../assets/images/state_svg/UT.svg"
import { ReactComponent as VA }  from "../../assets/images/state_svg/VA.svg"
import { ReactComponent as VT }  from "../../assets/images/state_svg/VT.svg"
import { ReactComponent as WA }  from "../../assets/images/state_svg/WA.svg"
import { ReactComponent as WI }  from "../../assets/images/state_svg/WI.svg"
import { ReactComponent as WV }  from "../../assets/images/state_svg/WV.svg"
import { ReactComponent as WY }  from "../../assets/images/state_svg/WY.svg"

const state_map = {
    "missouri":MO,
    "new_york":NY,
    "alaska":AK,
    "illinois":IL,
    "south_carolina":SC,
    "virginia":VA,
    "washington":WA,
    "vermont":VT,
    "south_dakota":SD,
    "georgia":GA,
    "connecticut":CT,
    "alabama":AL,
    "new_hampshire":NH,
    "oklahoma":OK,
    "colorado":CO,
    "ohio":OH,
    "maine":ME,
    "mississippi":MS,
    "utah":UT,
    "delaware":DE,
    "indiana":IN,
    "arkansas":AR,
    "iowa":IA,
    "new_jersey":NJ,
    "wisconsin":WI,
    "michigan":MI,
    "arizona":AZ,
    "massachusetts":MA,
    "north_carolina":NC,
    "montana":MT,
    "district_of_columbia":DC,
    "wyoming":WY,
    "minnesota":MN,
    "florida":FL,
    "kentucky":KY,
    "north_dakota":ND,
    "louisiana":LA,
    "maryland":MD,
    "texas":TX,
    "hawaii":HI,
    "rhode_island":RI,
    "kansas":KS,
    "pennsylvania":PA,
    "nebraska":NE,
    "tennessee":TN,
    "idaho":ID,
    "oregon":OR,
    "nevada":NV,
    "california":CA,
    "west_virginia":WV,
    "new_mexico":NM,
    "usa": USA,
}

const StateSVGComponent = ({ stateName, size, color }) => {
    const state_name = stateName.trim().toLowerCase().replace(/ /g, '_');
    const StateSVG = state_map[state_name]

    if (!StateSVG) {
        return <div></div>;
    }  
    const svgSize = size || '24px';
    const svgColor = color || 'black';
    
    return (
        <StateSVG style={{ width: svgSize, height: svgSize, fill: svgColor }} />
    );
};

export default StateSVGComponent;