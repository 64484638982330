import React from 'react';
import { useNavigate } from 'react-router-dom';
// import { Button } from 'react-bootstrap';
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";


const ReturnButton = (props) => {
  const navigate = useNavigate();
  let buttonName = "Return";
  if (Object.keys(props).length > 0 && props.name) {
    buttonName = props.name;
  }
  let buttoncolor = "info";
  if (Object.keys(props).length > 0 && props.color) {
    buttoncolor = props.color;
  }
  let link = -1;
  if (Object.keys(props).redirect > 0 && props.redirect) {
    link = props.redirect;
  }
  return (
    
    <MKBox mt={2} mb={1}>
      <MKButton variant="gradient" color={buttoncolor}  onClick={() => navigate(link)}>
        {buttonName}
      </MKButton>
    </MKBox>
  );
};

export default ReturnButton;