import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import axios from 'axios';
import useAuthHeader from 'react-auth-kit/hooks/useAuthHeader'
import AdminSidePanel from './AdminSidePanel';
import TableComponent from '../basics/TableComponent';

const AdminViewStripeProducts = () => {
    const authHeader = useAuthHeader();
    const [error, setError] = useState(null);
    const [productDetails, setProductDetails] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const config = {
            headers: {
                Authorization: authHeader
            }
        };

        axios.get(`${process.env.REACT_APP_API_URL}/admin_products/get_stripe_products/100`, config)
            .then(response => {
                setProductDetails(response.data);
                setIsLoading(false);
            })
            .catch(error => {
                setError(error.toString());
                setIsLoading(false);
            });
    }, []);

    if (error) {
        return <div>Error: {error}</div>;
    }
    
    if (isLoading) {
        return <div>Loading...</div>;
    }
    const headers = [
        "active",
        // "created",
        // "deleted",
        // "description",
        // "features",
        "id",
        // "images",
        // "livemode",
        // "metadata",
        "name",
        // "shippable",
        // "statement_descriptor",
        "type",
        // "updated"
    ]
    return (
    <Container fluid>
        <Row>
        <Col md={8}>
            <h1>Terra Grasp Admin Panel</h1>
            <Card>
            <Card.Header>Product Views</Card.Header>
                <TableComponent headers={headers} productData={productDetails.data} />
            </Card>
        </Col>
        <Col md={4}>
        <AdminSidePanel /> 
        </Col>
        </Row>
    </Container>
    );
    };

export default AdminViewStripeProducts;
