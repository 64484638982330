import React, { useState } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import './EmailVerification.css';
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

const EmailVerification = () => {
  const [verified, setVerified] = useState(false);
  const [message, setMessage] = useState('');
  const [showRetry, setShowRetry] = useState(false);
  let { email_verify_token } = useParams();
  const current_year = new Date().getFullYear();
  let navigate = useNavigate();

  const verifyEmail = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/users/verify_email/${email_verify_token}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.status === 200) {
        setVerified(true);
            navigate('/user/login'); 
        setShowRetry(false);
        setMessage('Your email has been successfully verified!');
      } else if (response.status === 406) {
        setVerified(false);
        setShowRetry(true);
        setMessage('Failed to verify email. Please try again.');
      } else {
        setMessage('An unexpected error occurred.');
      }
    } catch (error) {
      setMessage('An error occurred.');
    }
  };

  return (

  <MKBox height="60vh" display="flex" justifyContent="center" alignItems="center">
  <MKBox width="100%" mx="auto" pt={5}>
      <Grid container spacing={1} rowSpacing={5} justifyContent="center">
        <Grid item xs={11} sm={9} md={5} lg={4} xl={3}>
        <Card>
          <MKBox
            variant="gradient"
            bgColor="info"
            borderRadius="lg"
            coloredShadow="info"
            mx={2}
            mt={-3}
            pt={2.5}
            pb={2.875}
            px={2.5}
            textAlign="center"
            lineHeight={1}
          >
            <MKTypography variant="h4" fontWeight="medium" color="white" mt={1}>
              Verify your email address
            </MKTypography>
            <MKTypography variant="button" color="white">
              Please confirm that you want to use this as your TerraGrasp account email address.
            </MKTypography>
          </MKBox>
          {message && 
              <MKBox
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                mx={2}
                mt={2}
                pt={0}
                pb={1}
                px={.5}
                textAlign="center"
                lineHeight={1}
                >
              <>
                <MKTypography variant="h4" fontWeight="medium" color="white" mt={1}>
                  Failed to verify
                </MKTypography>
                <MKBox
                variant="gradient"
                bgColor="light"
                borderRadius="lg"
                mx={2}
                mt={1}
                pt={1}
                pb={1}
                px={2.5}
                textAlign="center"
                lineHeight={1}
                >
                  <MKTypography bgColor="light" variant="button" color="black" component={Link} to="/user/verify-email/resend-email-verification" >
                    If its been more than 24 hours please request a new confirmation email
                  </MKTypography>
                </MKBox>
              </>
              </MKBox>
            }

          <MKBox p={3}>
            <MKBox component="form" role="form">
              <MKBox mt={2} mb={1}>
                <MKButton variant="gradient" color="info" fullWidth onClick={verifyEmail}>
                  Verify Email
                </MKButton>
              </MKBox>
              <MKBox mt={1} textAlign="center">
                <MKTypography variant="button" color="text">
                  <br></br>&copy; {current_year} TerraGrasp. All rights reserved. TerraGrasp
                </MKTypography>
              </MKBox>
            </MKBox>
          </MKBox>
        </Card>
        </Grid>
      </Grid>
    </MKBox>
  </MKBox>

  );
};

export default EmailVerification;