import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import './ScrollableTable.css';
import MKBox from "components/MKBox";
import ListItem from "components/basics/ListItem";
import { Link } from "react-router-dom";

function ContentBlock() {
  return (
    <>
    <MKBox component="section" >
      <Container>
        <Grid container spacing={0} alignItems="center" sx={{ mt: 6 }}>
          <Grid item xs={12} md={12} sx={{ mr: "auto", ml: { xs: 0, md: 12 } }}>
            <ListItem title="Improve Business Decisions with Income Data">
            Utilize the latest 2022 census income data by ZIP code to enhance your business decisions. Access comprehensive income statistics by ZIP code to target high-income areas, optimize marketing strategies, and drive revenue growth."
            </ListItem>
            <ListItem title="Our prepared lists provide all the information you need">
            Our prepared lists provide all the information you need, including household size, household type, median income, and <Link to="/income-by-zipcode">more</Link>. Access comprehensive income data to make informed business decisions and optimize your strategies.
            </ListItem>
          </Grid>
        </Grid>
      </Container>
    </MKBox>

   </>
  );
}

export default ContentBlock;

