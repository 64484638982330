import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

const ResendEmailVerification = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [submited, setSubmited] = useState('');
  const current_year = new Date().getFullYear();


  // Handle email input change
  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  // Handle form submission
  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/users/resend_email_verification`, { email });
      if (response.status === 200) {
        setMessage('Please check your email for verification instructions.');
        setSubmited(true)
        setEmail("");
      }
    } catch (error) {
      
        setMessage("There was an error sending the verification email.");
    }
  };

  return (
  <>
  <MKBox height="60vh" display="flex" justifyContent="center" alignItems="center">
  <MKBox width="100%" mx="auto" pt={5}>
      <Grid container spacing={1} rowSpacing={5} justifyContent="center">
        <Grid item xs={11} sm={9} md={5} lg={4} xl={3}>
        <Card>
        {!submited &&
          <MKBox variant="gradient" bgColor="info" borderRadius="lg" coloredShadow="info" mx={2} mt={-3} pt={2.5} pb={2.875} px={2.5} textAlign="center" lineHeight={1} >
            <MKTypography variant="h4" fontWeight="medium" color="white" mt={1}>
              Request new email verification code
            </MKTypography>
            <MKTypography variant="button" color="white">
              Please enter the email address you set up your account with to recieve a new verification code.
            </MKTypography>
          </MKBox> 
        }
              {message && (
                <MKBox variant="gradient" bgColor="info" borderRadius="lg" coloredShadow="info" mx={2} mt={-3} pt={2.5} pb={2.875} px={2.5} textAlign="center" lineHeight={1} >
                  <MKTypography variant="h4" fontWeight="medium" color="white" mt={1}>
                    Success!
                  </MKTypography>
                  <MKTypography variant="button" color="white">
                  {message}
                  </MKTypography>
                </MKBox>
              )}
              <MKBox p={3}>
                <MKBox component="form" role="form" onSubmit={handleSubmit}>  {/* Use onSubmit here */}
                  <MKBox mb={2}>
                    <MKInput 
                      type="email"
                      placeholder="Enter email"
                      name="email"
                      value={email}
                      label="Email"
                      fullWidth
                      onChange={handleEmailChange}
                      required
                    />
                  </MKBox>
                  <MKBox  >
                    <MKButton type="submit" variant="gradient" color="info" fullWidth>
                      Resend Verification Email
                    </MKButton>
                  </MKBox>
                  <MKBox mt={-2} textAlign="center">
                    <MKTypography variant="button" color="text">
                      <br></br>&copy; {current_year} TerraGrasp. All rights reserved. TerraGrasp
                    </MKTypography>
                  </MKBox>
                </MKBox>
              </MKBox>
            </Card>
          </Grid>
        </Grid>
      </MKBox>
    </MKBox>
  </>
  );
};

export default ResendEmailVerification;
