import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { EyeSlashFill, EyeFill } from 'react-bootstrap-icons';
import axios from 'axios';
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import { useCart } from 'react-use-cart';


const SignUp = (props) => {
  const [formValues, setFormValues] = useState({
    email: '',
    first_name: '',
    last_name: '',
    password: '',
    company_name: '',
  });

  const [isSubmitted, setIsSubmitted] = useState(false); 
  const [showAlert, setShowAlert] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [passwordsCriteria, setPasswordsCriteria] = useState(true);
  const [message, setMessage] = useState('');
  const [checkoutSignUp, setCheckoutSignUp] = useState(props.checkoutSignUp);
  const [showPassword, setShowPassword] = useState(false);
  const {items,} = useCart();

  function validatePassword(password) {
    const results = {
      isValid: true,
      messages: [],
    };
  
    if (password.length < 8) {
      results.isValid = false;
      results.messages.push('\u2B21 Password must be at least 8 characters long.');
    }
    if (!/\d/.test(password)) {
      results.isValid = false;
      results.messages.push('\u2B21 Password must include at least one number.');
    }
    if (!/[A-Z]/.test(password)) {
      results.isValid = false;
      results.messages.push('\u2B21 Password must include at least one uppercase letter.');
    }  
    return results;
  }

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormValues(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault(); 
  
    const passwordValidation = validatePassword(formValues.password);
  
    if (!passwordValidation.isValid) {
      setPasswordsCriteria(false);
      setShowAlert(false);
      setErrorMessage(passwordValidation.messages);
    } else {
      setErrorMessage([""]);
      setPasswordsCriteria(true);
  
      axios.post(`${process.env.REACT_APP_API_URL}/users/signup`, formValues)
        .then(response => {
          setShowAlert(true);
          setMessage('Sign Up Successful. Please check your email for verification instructions.');
          if (checkoutSignUp === true) {
            const checkoutData = {
              "product_ids": items.map((item) => (item.id))
            }
            checkoutData.email = formValues.email;
            if (checkoutData["product_ids"].length > 0) {
              axios.post(`${process.env.REACT_APP_API_URL}/generate_checkout`, checkoutData)
                .then(response => {
                  window.location.href = response.data.checkout_url
                })
                .catch(error => {
                  setErrorMessage([error.toString()]);
                });
              }
            }
        })
        .catch(error => {          
          if (error.response && error.response.status === 400) {
            setErrorMessage(["Are you sure you don't have account? Try logging in with the provided email"]);
          } else {
            console.error("There was an error submitting the form!");
            setErrorMessage(["There was an error submitting the form!"]);
          }
        });
      setIsSubmitted(true);
    }
  };
  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <>
      {isSubmitted && showAlert ? (
    <MKBox height="60vh" display="flex" justifyContent="center" alignItems="center">
    <MKBox width="100%" mx="auto" pt={5}>
        <Grid container spacing={1} rowSpacing={5} justifyContent="center">
          <Grid item xs={11} sm={9} md={5} lg={4} xl={3}>
          <Card>
            <MKBox variant="gradient" bgColor="info" borderRadius="lg" coloredShadow="info" mx={2} mt={-3} pt={2.5} pb={2.875} px={2.5} textAlign="center" lineHeight={1}>
              <MKTypography variant="h4" fontWeight="medium" color="white" mt={1}>
              Sign Up Successful
              </MKTypography>
              <MKTypography variant="button" color="white">
                Please check your email ({formValues.email}) for verification instructions.
              </MKTypography>
            </MKBox>
            <MKBox p={3}>
              <MKBox component="form" role="form">
                <MKBox mt={2} mb={1}>
                  <MKButton variant="gradient" color="info" fullWidth component={Link} to="/user/verify-email/check-email">
                    Login
                  </MKButton>
                </MKBox>
                <MKBox mt={1} textAlign="center">
                  <MKTypography variant="button" color="text">
                    <MKTypography component={Link} to="/" variant="button" color="info" fontWeight="medium" textGradient>
                      Return to Home Page
                    </MKTypography>
                  </MKTypography>
                </MKBox>
              </MKBox>
            </MKBox>
          </Card>
          </Grid>
        </Grid>
      </MKBox>
    </MKBox>


      ) : (

      <MKBox height="60vh" display="flex" justifyContent="center" alignItems="center">
      <MKBox width="100%" mx="auto" pt={5}>
          <Grid container spacing={1} rowSpacing={5} justifyContent="center">
            <Grid item xs={11} sm={9} md={5} lg={4} xl={3}>
            <Card>
              <MKBox
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                mx={2}
                mt={-3}
                pt={2.5}
                pb={2.875}
                px={2.5}
                textAlign="center"
                lineHeight={1}
              >
                <MKTypography variant="h4" fontWeight="medium" color="white" mt={1}>
                  Sign Up
                </MKTypography>
              </MKBox>
              <MKBox p={3}>
                <MKBox component="form" role="form">
                {errorMessage && errorMessage.length > 0 && errorMessage.map((msg, index) => (
                  <MKBox key={index} mb={2}>
                      <MKTypography variant="button" color="error">
                          {msg}
                      </MKTypography>
                  </MKBox>
                ))}

                  <MKBox mb={2}>
                    <MKInput 
                      type="email"
                      label="Enter email"
                      name="email"
                      fullWidth
                      value={formValues.email}
                      onChange={handleChange}
                      required
                      />
                  </MKBox>
                  <MKBox mb={2}>
                    <MKInput 
                      type="text"
                      label="Enter first name"
                      name="first_name"
                      fullWidth
                      value={formValues.first_name}
                      onChange={handleChange}
                      required
                      />
                  </MKBox>
                  <MKBox mb={2}>
                    <MKInput 
                      type="text"
                      label="Enter last name"
                      name="last_name"
                      fullWidth
                      value={formValues.last_name}
                      onChange={handleChange}
                      required
                      />
                  </MKBox>
                  <MKBox mb={2}>
                    <MKInput 
                      type={showPassword ? 'text' : 'password'}
                      label="Password"
                      name="password"
                      sx={{ width: '85%' }}
                      value={formValues.password}
                      onChange={handleChange}
                      required
                      />
                      <MKButton
                          onClick={toggleShowPassword}
                          iconOnly
                        >
                          {showPassword ? <EyeFill size={100}/> : <EyeSlashFill size={100}/>}
                    </MKButton>
                  </MKBox>
                  <MKBox mb={2}>
                    <MKInput 
                      type="text"
                      label="Enter company name"
                      name="company_name"
                      fullWidth
                      value={formValues.company_name}
                      onChange={handleChange}
                      />
                  </MKBox>
                  <MKBox mt={2} mb={1}>
                    <MKButton variant="gradient" color="info" fullWidth onClick={handleSubmit}>
                      Sign Up
                    </MKButton>
                  </MKBox>
                  <MKBox mt={1} textAlign="center">
                    <MKTypography variant="button" color="text">
                      <MKTypography component={Link} to="/user/login" variant="button" color="info" fontWeight="medium" textGradient >
                        Already Have an Account? 
                      </MKTypography>
                    </MKTypography>
                  </MKBox>
                </MKBox>
              </MKBox>
            </Card>
            </Grid>
          </Grid>
        </MKBox>
      </MKBox>
      )}
      </>
  )}

export default SignUp;
