/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import React from 'react';
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKBadge from "components/MKBadge";
import MKTypography from "components/MKTypography";

// Material Kit 2 PRO React components
import ProductCard from "../basics/ProductCard";
import StateImage from "components/images/StateImages";
import MKButton from "components/MKButton";
import { Link } from "react-router-dom";
import RetrieveImage from "components/images/GetImages";
import ContentBlock from "./ContentBlock";


function Products() {

  return (
    <MKBox component="section" py={2}>
      <Container>
        <Grid
          container
          item
          flexDirection="column"
          alignItems="center"
          xs={12}
          lg={6}
          sx={{ textAlign: "center", mx: "auto" }}
        >
          <MKBadge
            variant="contained"
            color="info"
            badgeContent="Solutions"
            container
            circular
            sx={{ mb: 1 }}
          />
          <MKTypography variant="h2" mb={1}>
            More Than Just Income Data {" "}
          </MKTypography>

        </Grid>

        <Grid container spacing={2} sx={{ mt: 2 ,display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

        <Grid item xs={12} md={10} lg={10} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <MKBox mt={3} sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
              <ProductCard
                image={StateImage("USA")}
                title="Income By Zip code"
                description="Looking for income data for every zip code in your state but can't find it or waste the time to track it down. Check out our quick and easy solutions. We pull the data for you to save you time and money!"
                action={{
                  type: "internal",
                  route: "/income-by-zipcode",
                  variant: "gradient",
                  color: "warning",
                  label: "Get Data",
                }}
              />
            </MKBox>
          </Grid>
          </Grid>
          <ContentBlock />

        <Grid container spacing={2} sx={{ mt: 2 }}>
          <Grid item xs={12} md={6} lg={6}>
            <MKBox mt={3}>
              <ProductCard
                image={RetrieveImage("api")}
                title="API Integration"
                description="Looking for a dependable and straightforward data API to meet all your data needs?"
                action={{
                  type: "internal",
                  route: "/api-inquery",
                  color: "dark",
                  label: "Ask us about our API",
                }}
              />
            </MKBox>
          </Grid>

          <Grid item xs={12} md={6} lg={6}>
            <MKBox mt={3}>
              <ProductCard
              
                image={RetrieveImage("dall_1")}
                title="Data Request"
                description="Do you need specific census data but don't have the time or know how?"
                action={{
                  type: "internal",
                  route: "/data-request",
                  color: "dark",
                  label: "Send us a request",
                }}
              />
            </MKBox>
          </Grid>
        </Grid>
        <Grid
          container
          item
          flexDirection="column"
          alignItems="center"
          xs={12}
          lg={6}
          sx={{ textAlign: "center", mx: "auto" }}
        >

          <MKTypography variant="h2" mb={1} mt={4}>
            Still looking?
          </MKTypography>
          <MKTypography variant="body2" color="text" mb={3} mt={2}>
          Looking for something your don't see? Our expert data team is here help. Simple send us a data request and we can get you what you need!
        </MKTypography>
        <MKButton variant="gradient" color="warning" component={Link} to='/unique-request'
        size="large"
        sx={{ fontSize: '18px', width: '80%' }} >
          Send Data Inquery 
        </MKButton>
        </Grid>

      </Container>
    </MKBox>
  );
}

export default Products;