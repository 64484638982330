// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Stack from "@mui/material/Stack";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Coworking page component
import DataTypeDisply from "./DataTypeDisply";

function DataTypeSection() {
  return (
    <Grid
    container
    flexDirection="column"
    alignItems="center"
    borderRadius="16px"
    sx={{ 
        textAlign: "center", 
        background: 'dark', 
    }}
>
    <MKBox 
    bgColor="light"
    // validColors="grey-100"
    position="relative"
    borderRadius="16px"
    component="section" py={{ xs: 3, md: 2 }}>
        <MKTypography variant="h3" my={1}>
            Key Features By Zip Code
        </MKTypography>
        <Container>
            <DataTypeDisply
                flip={false}
                title='Detailed Income Brackets'
                icon="attach_money"
                content={
                    <> 
                        Our data provides a detailed breakdown of household income distribution across various brackets, ranging from less than $10,000 to over $200,000. For each income bracket, we offer both the percentage of the population within each ZIP code and the actual count of households. This allows you to gain a comprehensive understanding of the economic landscape in any given area.
                    </>
                }
            />
            <DataTypeDisply
                flip={false}
                title='Median and Mean Household Income'
                icon="functions"
                content={<> Get precise data on median and mean household incomes to assess the economic health and average earning potential within specific zip codes.</> }
            />
            <DataTypeDisply
                flip={false}
                title='Retirement, Social Security, and Income Insights'
                icon="elderly"
                content={
                    <>
                        Access critical data on Retirement, Wage Or Salary, Self-Employment, Interest Dividends or Net Rental, Social Security, Supplemental Security (SSI), Public Assistance, Cash Public Assistance Or Food Stamps/Snap, and Earning income by zipcode to identify areas with high concentrations of retirees. This is invaluable for industries targeting senior citizens, such as healthcare, real estate, and financial services.
                    </>
                }
            />
            <DataTypeDisply
                flip={false}
                title='Age-Specific Income Data'
                icon="cake"
                content={<> Analyze income data segmented by age groups (15-24, 25-44, 45-64, and 65+). This segmentation is crucial for tailoring products and services to different age demographics, understanding their spending power, and planning age-targeted marketing campaigns.</> }
            />
            <DataTypeDisply
                flip={false}
                title='Household Composition Data'
                icon="family_restroom"
                content={<> Understand the income dynamics based on household sizes and the number of earners. This includes data for households with no earners, single earners, and multiple earners, providing a comprehensive view of the income distribution.</> }
            />
        </Container>
    </MKBox>
    </Grid>
  );
}

export default DataTypeSection;