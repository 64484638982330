import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

const CheckEmailForVerification = () => {
  const current_year = new Date().getFullYear();


  return (
  <>
  <MKBox height="60vh" display="flex" justifyContent="center" alignItems="center">
  <MKBox width="100%" mx="auto" pt={5}>
      <Grid container spacing={1} rowSpacing={5} justifyContent="center">
        <Grid item xs={11} sm={9} md={5} lg={4} xl={3}>
        <Card>
          <MKBox variant="gradient" bgColor="info" borderRadius="lg" coloredShadow="info" mx={2} mt={-3} pt={2.5} pb={2.875} px={2.5} textAlign="center" lineHeight={1} >
            <MKTypography variant="h4" fontWeight="medium" color="white" mt={1}>
              Please Verify your email address
            </MKTypography>
            <MKTypography variant="button" color="white">
            It looks like your email is not yet verified. Please check your email for a verification message and follow the instructions to verify your email address.
            </MKTypography>
          </MKBox> 

              <MKBox p={3}>
                    <MKButton type="submit" variant="gradient" color="info" fullWidth component={Link} to="/user/verify-email/resend-email-verification">
                      Resend Verification Email
                    </MKButton>
                  </MKBox>
                  <MKBox mt={-2} textAlign="center">
                    <MKTypography variant="button" color="text">
                      <br></br>&copy; {current_year} TerraGrasp. All rights reserved. TerraGrasp
                    </MKTypography>
              </MKBox>
            </Card>
          </Grid>
        </Grid>
      </MKBox>
    </MKBox>
  </>
  );
};

export default CheckEmailForVerification;
