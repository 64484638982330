import React from 'react';

import USA from "../../assets/images/usa.webp";
import dall_1 from "../../assets/images/dall_1.webp";
import small_logo from "../../assets/images/logo_small.webp";
import APIImage from "../../assets/images/API.webp";

const Images = {
    'USA':USA,
    'dall_1':dall_1,
    'small_logo':small_logo,
    'api': APIImage
}


export const RetrieveImage = (imageName) => { 
    return Images[imageName]
    // return California
};


export default RetrieveImage