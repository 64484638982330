import React, { useState } from 'react';
import axios from 'axios';
import { Form, Button, Alert, Row, Col, Container } from 'react-bootstrap';
import { Link} from 'react-router-dom';
import useAuthHeader from 'react-auth-kit/hooks/useAuthHeader'
import AdminSidePanel from './AdminSidePanel';

const AdminCreateProduct = () => {
  const defaultForm = {
    product_name: '',
    file_path: '',
    price: 0,
    description: '',
    product_year: 0,
    product_type: '',
    image_path: '',
    aws_bucket_path: '',
    aws_file_name: '',
  status: false
  }
  const [formData, setFormData] = useState(defaultForm);
  const [showAlert, setShowAlert] = useState(false);
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const authHeader = useAuthHeader()
  
  const handleChange = (e) => {
    const { name, value } = e.target;
    
    let valueToUpdate = value;
    if ((name === 'product_year' || name === 'price') && value !== '') {
      valueToUpdate = parseInt(value, 10); 
    }
    setFormData(prevState => ({
      ...prevState,
      [name]: valueToUpdate
    }));
  }

  const handleSubmit = (e) => {
    setShowAlert(false);
    setShowErrorAlert(false);
    e.preventDefault();
    const config = {
      headers: {
        Authorization: authHeader
      }
    }
    const submissionData = {
      ...formData,
      status: formData.status === 'true', // This will be true if status is 'true', false otherwise
    };

    axios.post(`${process.env.REACT_APP_API_URL}/products/`, submissionData, config)
      .then(response => {
        setFormData(defaultForm)
        setShowAlert(true);
        setShowErrorAlert(false);
      })
      .catch(error => {
        setShowAlert(false);
        setShowErrorAlert(true);
        setAlertMessage(error.response.data);
      });
  };

  return (
    <Container fluid>
    <Row>
      <Col md={8}>
        <h1>Terra Grasp Admin Panel</h1>
      <h1>Create Product</h1>
      {showAlert && (
        <Alert variant="success" onClose={() => setShowAlert(false)} dismissible>
          Product successfully created!
        </Alert>
      )}
      {showErrorAlert && (
        <Alert variant="danger" onClose={() => setShowErrorAlert(false)} dismissible>
          {alertMessage}
        </Alert>
      )}
      <Form onSubmit={handleSubmit}>
        <Form.Group controlId="product_name">
          <Form.Label>Product Name</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter product name"
            name="product_name"
            value={formData.product_name}
            onChange={handleChange}
            required
          />
        </Form.Group>

        <Form.Group controlId="file_path">
          <Form.Label>File Path</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter file path"
            name="file_path"
            value={formData.file_path}
            onChange={handleChange}
            required
          />
        </Form.Group>

        <Form.Group controlId="price">
          <Form.Label>Price</Form.Label>
          <Form.Control
            type="number"
            placeholder="Enter price"
            name="price"
            value={formData.price}
            onChange={handleChange}
            required
          />
        </Form.Group>

        <Form.Group controlId="description">
          <Form.Label>Description</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter description"
            name="description"
            value={formData.description}
            onChange={handleChange}
            required
          />
        </Form.Group>

        <Form.Group controlId="product_year">
          <Form.Label>Product Year (optional)</Form.Label>
          <Form.Control
            type="number"
            placeholder="Enter product year"
            name="product_year"
            value={formData.product_year}
            onChange={handleChange}
          />
        </Form.Group>

        <Form.Group controlId="product_type">
          <Form.Label>Product Type (optional)</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter product type"
            name="product_type"
            value={formData.product_type}
            onChange={handleChange}
          />
        </Form.Group>

        <Form.Group controlId="image_path">
          <Form.Label>Image Path (optional)</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter image path"
            name="image_path"
            value={formData.image_path}
            onChange={handleChange}
          />
        </Form.Group>
        <Form.Group controlId="aws_bucket_path">
          <Form.Label>Bucket Name</Form.Label>
          <Form.Control
            type="text"
            placeholder="This is the bucket"
            name="aws_bucket_path"
            value={formData.aws_bucket_path}
            onChange={handleChange}
            required
          />
        </Form.Group>
        <Form.Group controlId="aws_file_name">
          <Form.Label>File Name</Form.Label>
          <Form.Control
            type="text"
            placeholder="Any subdirectory and the file name EX: income/utah.csv"
            name="aws_file_name"
            value={formData.aws_file_name}
            onChange={handleChange}
            required
          />
        </Form.Group>
        <Form.Group controlId="status">
          <Form.Label>Product Status</Form.Label>
          <Form.Select
            name="status"
            value={formData.status}
            onChange={handleChange}
            required
          >
            <option value="">Select product status</option>
            <option value="true">Active</option>
            <option value="false">Deactive</option>
          </Form.Select>
          <Form.Control.Feedback type="invalid">
            Please provide a product type.
          </Form.Control.Feedback>
        </Form.Group>

        <Row>
          <Col md={6}>
            <Button variant="primary" type="submit">
              Submit
            </Button>
          </Col>
          <Col md={6}>
            <Link to="/admin/panel">
              <Button variant="link">
                Admin Panel
              </Button>
            </Link>
          </Col>
        </Row>
      </Form>
      </Col>
      <Col md={4}>
    <AdminSidePanel /> 
        </Col>
      </Row>
    </Container>
  );
};

export default AdminCreateProduct;
