import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import axios from 'axios';
import useAuthHeader from 'react-auth-kit/hooks/useAuthHeader'
import AdminSidePanel from './AdminSidePanel';
import TableComponent from '../basics/TableComponent';

const AdminViewStripePrices = () => {
    const authHeader = useAuthHeader();
    const [error, setError] = useState(null);
    const [priceDetails, setPriceDetails] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const config = {
            headers: {
                Authorization: authHeader
            }
        };

        axios.get(`${process.env.REACT_APP_API_URL}/admin_products/get_stripe_prices/100`, config)
            .then(response => {
                setPriceDetails(response.data);
                setIsLoading(false);
            })
            .catch(error => {
                setError(error.toString());
                setIsLoading(false);
            });
    }, []); // Removed navigate and authHeader from dependencies

    if (error) {
        return <div>Error: {error}</div>;
    }
    
    if (isLoading) {
        return <div>Loading...</div>;
    }
    const headers = [
        "active",
        "billing_scheme",
        // "created",
        "currency",
        // "deleted",
        "id",
        "livemode",
        // "metadata",
        "product",
        "tax_behavior",
        "type",
        "unit_amount",
        "unit_amount_decimal",
    ]
    return (
    <Container fluid>
        <Row>
        <Col md={8}>
            <h1>Terra Grasp Admin Panel</h1>
            <Card>
            <Card.Header>price Views</Card.Header>
                <TableComponent headers={headers} productData={priceDetails.data} />
            </Card>
        </Col>
        <Col md={4}>
        <AdminSidePanel /> 
        </Col>
        </Row>
    </Container>
    );
    };

export default AdminViewStripePrices;
