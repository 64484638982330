export function ConvertPrice(price) {
    return price.toFixed(2)/100
}

export function CleanProductType(productType) {
    return productType
    .split('_')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');
}

export function capitalizeAndReplaceUnderscores(str) {
    return str
      .split('_')  // Split the string by underscores
      .map(part => 
        part
          .toLowerCase()  // Convert the whole string to lowercase
          .replace(/^\w/, c => c.toUpperCase())  // Capitalize the first character of each part
      )
      .join(' ');  // Join the parts back with spaces
  }