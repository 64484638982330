import React from 'react';
import "./App.css";
import {createBrowserRouter, RouterProvider } from 'react-router-dom';
import RootLayout from "./components/RootLayout";
import Login from "./components/Users/Login";
import ForgotPassword from "./components/Users/ForgotPassword";
import SignUp from "./components/Users/SignUp";
import Contact from "./components/Forms/Contact";
import AdminCreateProduct from './components/Admin/AdminCreateProduct';
import AccountPage from "./components/Users/AccountPage";
import Logout from "./components/Users/Logout";
import EmailVerification from "./components/Users/EmailVerification";
import ResendEmailVerification from "./components/Users/ResendEmailVerification";
import PasswordReset from "./components/Users/ResetPassword";
import AdminPanel from "./components/Admin/AdminPanel";
import IncomeByZip from "./components/product/IncomeByZip";
import AdminViewProducts from "./components/Admin/AdminViewProducts";
import AdminCheck from "./components/Admin/AdminCheck";
import ErrorPage from "./components/Error";
import AccountCheck from "./components/Users/AccountCheck";
import ShoppingCart from "./components/Cart/ShoppingCart";
import AdminViewStripeProducts from "./components/Admin/AdminViewStripeProducts";
import AdminViewStripePrices from "./components/Admin/AdminViewStripePrices";
import AdminDetailProduct from "./components/Admin/AdminProductDetails";
import CheckoutLogin from "./components/Users/CheckoutLogin";
import CustomLanding from "./components/CustomLanding";
import AboutUs from "./components/basics/AboutUs";
import AdminCreateProductCSVUpload from "components/Admin/AdminCreateProductCSVUpload";
import APIRequest from "components/Forms/API_request";
import DataRequest from "components/Forms/DataRequest";
import UniqueRequest from "components/Forms/UniqueRequest";
import CheckoutSuccessRedirect from "components/Users/CheckoutReturn";
import CheckEmailForVerification from "components/Users/CheckEmailForVerification";
import Sitemap from "components/SiteMap";
import SitemapLink from 'components/SiteMapLinks';

const router = createBrowserRouter([
  {path: "/", 
    element: <RootLayout relative_status={false}  />,
    errorElement: <RootLayout error={true} /> ,
    children: [
      { path: '/*' , element: <ErrorPage />},
      { index: true, element: <CustomLanding />},
      { path: 'about-us' , element: <AboutUs/>},
    ]
  },
  {path: "/sitemap.xml", 
    element: <Sitemap />,
  },
  {path: "/", 
    element: <RootLayout relative_status={true}  />,
    errorElement: <RootLayout error={true} /> ,

    children: [
      { path: 'sitemap' , element: <SitemapLink/>},
      { path: 'contact-us' , element: <Contact />},
      { path: 'api-inquery' , element: <APIRequest />},
      { path: 'data-request' , element: <DataRequest />},
      { path: 'unique-request' , element: <UniqueRequest />},
      { path: 'income-by-zipcode' , element: <IncomeByZip/>}, //____UPDATE____
      {path: "/user", 
        errorElement: <RootLayout error={true} /> ,
        children: [
          { path: 'login', element: <Login />},
          { path: 'checkout-success', element: <CheckoutSuccessRedirect />},
          { path: 'login-checkout', element: <CheckoutLogin />},
          { path: 'logout', element: <Logout /> },
          { path: 'reset', element: <ForgotPassword />},
          { path: 'reset-password/:password_reset_token', element: <PasswordReset/>},
          { path: 'sign-up', element: <SignUp/>},
          { path: 'sign-in-new-user', element: <SignUp/>},
          { path: 'sign-up-checkout', element: <SignUp checkoutSignUp={true}/>},
          { path: 'verify-email/resend-email-verification', element: <ResendEmailVerification/>},
          { path: 'verify-email/check-email', element: <CheckEmailForVerification/>},
          { path: 'verify-email/:email_verify_token', element: <EmailVerification/>},
          { path: 'cart', element: <ShoppingCart />},
          ]
      }]
    },
    {path: "/", 
    element: <RootLayout relative_status={true}  />,
    errorElement: <RootLayout error={true} /> ,
    children: [
      {path: "/account", 
      element: <AccountCheck />,
      errorElement: <RootLayout error={true} /> ,
      children: [
        { path: '', element: <AccountPage/>},
        ]
      },
      {path: "/admin", 
      element: <AdminCheck />,
      errorElement: <RootLayout error={true} /> ,
      children: [
        { path: 'create-product' , element: <AdminCreateProduct />},
        { path: 'create-product-csv' , element: <AdminCreateProductCSVUpload />},
        { path: 'groups' , element: <></>},
        { path: 'users' , element: <></>},
        { path: 'stripe_products' , element: <AdminViewStripeProducts />},
        { path: 'stripe_prices' , element: <AdminViewStripePrices />},
        { path: 'products' , element: <AdminViewProducts/>},
        { path: 'detailed-product/:id' , element: <AdminDetailProduct/>},
        {path: 'panel', element: <AdminPanel/> },
        ]
    }
  ]
},
{path: "/sitemap.xml", 
  element: <Sitemap />,
},
]);

function RoutesComponent() {
  return (
  <RouterProvider router={router} />
  );
}

export default RoutesComponent;

// Site map and how to build it
// https://terragrasp.com
// https://terragrasp.com/about-us
// https://terragrasp.com/contact-us
// https://terragrasp.com/data-request
// https://terragrasp.com/income-by-zipcode
// https://terragrasp.com/user/cart
// https://www.countingcharacters.com/xml-sitemap-generator#priority