import React from 'react';
import { Link } from 'react-router-dom';
import useSignOut from 'react-auth-kit/hooks/useSignOut';
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

function Logout() {
      const signOut = useSignOut();

      return (
  <MKBox height="60vh" display="flex" justifyContent="center" alignItems="center">
    <MKBox width="100%" mx="auto" pt={5}>
        <Grid container spacing={1} rowSpacing={5} justifyContent="center">
          <Grid item xs={11} sm={9} md={5} lg={4} xl={3}>
          <Card>
            <MKBox
              variant="gradient"
              bgColor="secondary"
              borderRadius="lg"
              coloredShadow="info"
              mx={2}
              mt={-3}
              pt={2.5}
              pb={2.875}
              px={2.5}
              textAlign="center"
              lineHeight={1}
            >
              <MKTypography variant="h4" fontWeight="medium" color="white" mt={1}>
              Are you sure you want to Logout?
              </MKTypography>
            </MKBox>
            <MKBox p={3}>
              <MKBox mt={2} mb={1}>
                <MKButton
                variant="gradient"
                color="info"
                fullWidth
                component={Link}
                to="/"
                onClick={() => signOut()} >
                  Yes
                </MKButton>
              </MKBox>
              <MKBox mt={2} mb={1}>
                <MKButton 
                  variant="gradient"
                  color="info"
                  component={Link}
                  to="/account"
                  fullWidth 
                  onClick={() => signOut()}
                  >
                  No
                </MKButton>
              </MKBox>
            </MKBox>
          </Card>
          </Grid>
        </Grid>
      </MKBox>
    </MKBox>
      );
      
    }


export default Logout;
