import React, { useState } from "react"
import axios from 'axios'
import useAuthUser from 'react-auth-kit/hooks/useAuthUser';
import {useSignIn}  from 'react-auth-kit'
import { useCart } from 'react-use-cart';
import { Link,useNavigate } from 'react-router-dom';
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

const CheckoutLogin = () => {
  const [isLoading, setIsLoading] = useState(false); 
  const [showAlert, setShowAlert] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  let navigate = useNavigate();
  const signIn = useSignIn();
  const [formData, setFormData] = React.useState({email: '', password: ''})
  const {
      items,
  } = useCart();
  const userEmail = useAuthUser()

  const onSubmit = (e) => {
    e.preventDefault()
    setIsLoading(true);
    axios.post(`${process.env.REACT_APP_API_URL}/login`, formData)
        .then((res) => {
          setIsLoading(true);
          setShowAlert(false); 
          if (res.status === 200) {
              if (signIn({
                  token: res.data.token,
                  expiresIn: res.data.expiresIn,
                  tokenType: "Bearer",
                  authState: res.data.authUserState,
                  staff: res.data.staff,
                  email_verified: res.data.email_verified,
                  // Add other fields if necessary
              })) { 
                if (res.data.email_verified === "false"){
                  navigate('/user/verify-email/resend-email-verification'); 
                } else {
                  

                      const checkoutData = {
                          "product_ids": items.map((item) => (item.id))
                      }
                      
                      if (userEmail !== null) {
                          checkoutData.email = formData.email;
                      }
                      if (checkoutData["product_ids"].length > 0) {
                        axios.post(`${process.env.REACT_APP_API_URL}/generate_checkout`, checkoutData)
                        .then(response => {
                            window.location.href = response.data.checkout_url
                        })
                        .catch(error => {
                          setErrorMessage(error.toString());
                        });
                    }
                }
              } else {
              }
          }
      })
      .catch(error => {
        setIsLoading(false);
        setShowAlert(true); 
        if (error.response && error.response.status === 409) {
            setErrorMessage("There was an error logging in! Check that you are using the correct password or Email");
        } else {
            setErrorMessage("There was an error logging in! Check that you are using the correct password or Email"); // Generic error message
        }
      })
  };

return (
<MKBox height="60vh" display="flex" justifyContent="center" alignItems="center">
    <MKBox width="100%" mx="auto" pt={5}>

        <Grid container spacing={1} rowSpacing={5} justifyContent="center">
          <Grid item xs={11} sm={9} md={5} lg={4} xl={3}>
          <Card>
            <MKBox
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="info"
              mx={2}
              mt={-3}
              pt={2.5}
              pb={2.875}
              px={2.5}
              textAlign="center"
              lineHeight={1}
            >
              <MKTypography variant="h4" fontWeight="medium" color="white" mt={1}>
                Sign in
              </MKTypography>
              <MKTypography variant="button" color="white">
                Welcome back
              </MKTypography>
            </MKBox>
            {showAlert && 
              <MKBox
              variant="gradient"
              bgColor="secondary"
              borderRadius="lg"
              mx={2}
              mt={3}
              pt={2}
              pb={2}
              px={2.5}
              textAlign="center"
              lineHeight={1}
            >
                <MKTypography variant="button" color="white">
                  {errorMessage}
                </MKTypography>
                </MKBox>
              }
            <MKBox p={3}>
              <MKBox component="form" role="form">
                <MKBox mb={2}>
                  <MKInput 
                    type="email"
                    label="Email"
                    fullWidth value={formData.email}
                    onChange={(e)=>setFormData({...formData, email: e.target.value})}
                    required />
                </MKBox>
                <MKBox mb={2}>
                  <MKInput 
                    type="password"
                    label="Password"
                    fullWidth
                    value={formData.password}
                    onChange={(e)=>setFormData({...formData, password: e.target.value})}
                    required/>
                </MKBox>

                <MKBox mt={2} mb={1}>
                  <MKButton variant="gradient" color="info" fullWidth onClick={onSubmit} disabled={isLoading}>
                    {isLoading ? 'Signing in...' : 'Sign in and Checkout'} 
                  </MKButton>
                </MKBox>
                <MKBox mt={3} textAlign="center">
                  <MKTypography variant="button" color="text">
                    Don&apos;t have an account?{" "}
                    <MKTypography
                      component={Link}
                      to="/user/sign-up-checkout"
                      variant="button"
                      color="info"
                      fontWeight="medium"
                      textGradient
                    >
                      Sign up and Checkout
                    </MKTypography>
                  </MKTypography>
                </MKBox>
              </MKBox>
            </MKBox>
          </Card>
          </Grid>
        </Grid>
      </MKBox>
    </MKBox>
    )
}
export default CheckoutLogin;
