import React from 'react';
import { Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const TableComponent = ({ headers, productData, link}) => {
    
    function renderValue(value, index) {
      if (index === 0 && typeof link === 'string') {
        return (
          <Link to={link+value.toString()}>
            {value.toString()}
          </Link>
        );
      } else if (typeof value === 'object') {
        return JSON.stringify(value);
      } else {
        return value.toString();
      }
    }
return (
  <Table responsive striped bordered hover size="sm">
  <thead>
      <tr>
          {headers.map((key, index) => (
              <th key={index}>{key}</th>
          ))}
      </tr>
  </thead>
  <tbody>
      {productData.map((product, productIndex) => (
          <tr key={product.id || productIndex}>
              {headers.map((key, index) => {
                  const value = product[key];
                  return (
                      <td key={index}>
                          {renderValue(value, index)}
                      </td>
                  );
              })}
          </tr>
      ))}
  </tbody>
</Table>
)
};


export default TableComponent;
