import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './CustomNavbar.css';
import useIsAuthenticated from 'react-auth-kit/hooks/useIsAuthenticated'
import { GetAdmin } from './Users/AuthDecode';
import DefaultNavbar from './basics/NavBar';
import routes, {admin_routes,signed_in_routes} from '../routes';
const CustomNavbar = (props) => {
  const isAuthenticated = useIsAuthenticated();
  const [userStatus, setUserStatus] = useState(isAuthenticated);
  const is_admin = GetAdmin();
  const [relative, setRelative] = useState(props.relative_status)

  const [currentRoutes, setCurrentRoutes] = useState(routes);

  useEffect(() => {
    setRelative(props.relative_status)
        setUserStatus(prev=>isAuthenticated) 
        if (is_admin === true) {
          setCurrentRoutes([...signed_in_routes, ...admin_routes]);
        } 
        else if (userStatus) {
          setCurrentRoutes(signed_in_routes);
        }
        else {
          setCurrentRoutes(routes);
        }
      }, [is_admin,userStatus,isAuthenticated,setRelative,relative,props.relative_status]);

  if (relative === true) {
    return (
      <>
      <DefaultNavbar
        routes={currentRoutes}
        action={{
          type: "internal",
          route: "/user/cart",
          label: "view cart",
          color: "info",
        }}
        relative 
        sticky
      />
      </>
    );
      } else{
        return (
          <>
          <DefaultNavbar
            routes={currentRoutes}
            action={{
              type: "internal",
              route: "/user/cart",
              label: "view cart",
              color: "info",
            }} 
            sticky
          />
          </>
        );
      }
};

export default CustomNavbar;
