import React, { useEffect, useState} from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import useAuthHeader from 'react-auth-kit/hooks/useAuthHeader'
import AdminSidePanel from './AdminSidePanel';

const AdminDetailProduct = () => {
   const authHeader = useAuthHeader();
   const [error, setError] = useState(null);
   const [productDetails, setProductDetails] = useState([]);
   const [isLoading, setIsLoading] = useState(true);
   let { id } = useParams();

  useEffect(() => {
    const config = {
      headers: {
        Authorization: authHeader
      }
    };
    // axios.get(`${process.env.REACT_APP_API_URL}/admin_products/get_terra_grasp_products/100`, config)
    axios.get(`${process.env.REACT_APP_API_URL}/admin_products/get_all_product_details/${id}`, config)
    .then(response => {
        setProductDetails(response.data);
        setIsLoading(false);
    })
    .catch(error => {
        setError(error.toString());
        setIsLoading(false);
    });
}, []);

if (error) {
    return <div>Error: {error}</div>;
}

if (isLoading) {
    return <div>Loading...</div>;
}

const rowStyles = [
  { backgroundColor: 'lightgray' }, // Light gray, for even rows
  { backgroundColor: '#e9ecef' }  // Slightly darker, for odd rows
];

return (

    <Container fluid>
    <Row>
    <Col md={8}>
        <h1>Terra Grasp Admin Panel</h1>
        <Container fluid>
        <Row>
      {['terra_grasp_product', 'stripe_product', 'stripe_price'].map(key => (
        <Col key={key} md={4}>
          <Card className="mb-3">
            <Card.Header>{key}</Card.Header>
            <Card.Body>
                {productDetails[key] ? (
                  Object.entries(productDetails[key]).map(([subKey, value], index) => (
                    <Card.Text key={subKey} style={rowStyles[index % 2]}>
                      <strong>{subKey}:</strong> {value != null ? value.toString() : 'null'}
                    </Card.Text>
                  ))
                ) : (
                  <p>{key} is present but has no data.</p>
                )}
            </Card.Body>
          </Card>
        </Col>
      ))}
    </Row>
    </Container>
    </Col>
    <Col md={4}>
    <AdminSidePanel /> 
    </Col>
    </Row>
  </Container>

  );
};

export default AdminDetailProduct;
