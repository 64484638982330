import React from 'react';
import CheckIcon from '@mui/icons-material/Check';
import PercentIcon from '@mui/icons-material/Percent';
import NumbersIcon from '@mui/icons-material/Numbers';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';

import './IncomeSchema.css';

import { green,blue,grey } from '@mui/material/colors';

const data = [

  {
    type: 'Median Household Income By Age ($$)',
    details: [
      {detail: 'Age 15-24', type: 'd' },
      {detail: 'Age 25-44', type: 'd' },
      {detail: 'Age 45-64', type: 'd' },
      {detail: 'Age 65+', type: 'd' },
    ]
  },
  {
    type: 'Household Income by Age',
    details: [
        {detail: 'Age 15-24', type: 'c' },
        {detail: 'Age 25-44', type: 'c' },
        {detail: 'Age 45-64', type: 'c' },
        {detail: 'Age 65+', type: 'c' },
      ]
    },
    {
      type: 'Median Family Income By Number Of Earners ($$)',
      details: [
          {detail: 'No earners', type: 'd' },
          {detail: '1 earner', type: 'd' },
          {detail: '2 earners', type: 'd' },
          {detail: '3 or more earners', type: 'd' },
      ]
    },
  {
    type: 'Number Of Family Income By Number Of Earners',
    details: [
      {detail: 'Earners 0', type: 'c' },
      {detail: 'Earners 1', type: 'c' },
      {detail: 'Earners 2', type: 'c' },
      {detail: '3 or more earners', type: 'c' },
    ]
  },

  {
    type: 'Households',
    details: [
      {detail: 'Median HH Income ($$)', type: 'd' },
      {detail: 'Mean HH Income ($$)', type: 'd' },
    ]
  },
  {
    type: 'Families Households',
    details: [
      {detail: 'Total', type: 'c' },
      {detail: 'Median income ($$)', type: 'd' },
      {detail: 'Mean income ($$)', type: 'd' },
    ]
  },
  {
    type: 'Married-couple families',
    details: [
      {detail: 'Total', type: 'c' },
      {detail: 'Median income ($$)', type: 'd' },
      {detail: 'Mean income ($$)', type: 'd' },
    ]
  },
  {
    type: 'Nonfamily households',
    details: [
      {detail: 'Total', type: 'c' },
      {detail: 'Median income ($$)', type: 'd' },
      {detail: 'Mean income ($$)', type: 'd' },
    ]
  },
  {
    type: 'Number of Family Income By Family Size',
    details: [
      {detail: 'Female HH, no spouse present', type: 'c' },
      {detail: 'Male HH, no spouse present', type: 'c' },
      {detail: 'Size 2', type: 'c' },
      {detail: 'Size 3', type: 'c' },
      {detail: 'Size 4', type: 'c' },
      {detail: 'Size 5', type: 'c' },
      {detail: 'Size 6', type: 'c' },
      {detail: 'Size 7+', type: 'c' },
    ]
  },

  {
    type: 'Median Family Income By Family Size($$)',
    details: [
      {detail: 'Female HH, no spouse present', type: 'd' },
      {detail: 'Male HH, no spouse present', type: 'd' },
      {detail: 'Size 2', type: 'd' },
      {detail: 'Size 3', type: 'd' },
      {detail: 'Size 4', type: 'd' },
      {detail: 'Size 5', type: 'd' },
      {detail: 'Size 6', type: 'd' },
      {detail: 'Size 7+', type: 'd' },
    ]
  },
  {
    type: 'Household Income Percent Distribution',
    details: [
      {detail: 'Total HH Count', type: 'c' },
      {detail: 'Less than $10,000', type: 'p' },
      {detail: '$10,000 to $14,999', type: 'p' },
      {detail: '$15,000 to $24,999', type: 'p' },
      {detail: '$25,000 to $34,999', type: 'p' },
      {detail: '$35,000 to $49,999', type: 'p' },
      {detail: '$50,000 to $74,999', type: 'p' },
      {detail: '$75,000 to $99,999', type: 'p' },
      {detail: '$100,000 to $149,999', type: 'p' },
      {detail: '$150,000 to $199,999', type: 'p' },
      {detail: '$200,000 or more', type: 'p' },
    ]
  },
  {
    type: 'Household Income distribution',
    details: [
      {detail: 'Total HH Count', type: 'c' },
      {detail: 'Less than $10,000', type: 'c' },
      {detail: '$10,000 to $14,999', type: 'c' },
      {detail: '$15,000 to $19,999', type: 'c' },
      {detail: '$20,000 to $24,999', type: 'c' },
      {detail: '$25,000 to $29,999', type: 'c' },
      {detail: '$30,000 to $34,999', type: 'c' },
      {detail: '$35,000 to $39,999', type: 'c' },
      {detail: '$40,000 to $44,999', type: 'c' },
      {detail: '$45,000 to $49,999', type: 'c' },
      {detail: '$50,000 to $59,999', type: 'c' },
      {detail: '$60,000 to $74,999', type: 'c' },
      {detail: '$75,000 to $99,999', type: 'c' },
      {detail: '$100,000 to $124,999', type: 'c' },
      {detail: '$125,000 to $149,999', type: 'c' },
      {detail: '$150,000 to $199,999', type: 'c' },
      {detail: '$200,000 or more', type: 'c' },
    ]
  },
  {
    type: 'Household Earning Types',
    details: [
      {detail: 'With Earnings', type: 'c' },
      {detail: 'No Earnings', type: 'c' },
      {detail: 'With Wage Or Salary Income', type: 'c' },
      {detail: 'No Wage Or Salary Income', type: 'c' },
      {detail: 'With Self-Employment Income', type: 'c' },
      {detail: 'No Self-Employment Income', type: 'c' },
      {detail: 'With Interest, Dividends, Or Net Rental Income', type: 'c' },
      {detail: 'No Interest, Dividends, Or Net Rental Income', type: 'c' },
      {detail: 'With Social Security Income', type: 'c' },
      {detail: 'No Social Security Income', type: 'c' },
      {detail: 'With Supplemental Security Income (Ssi)', type: 'c' },
      {detail: 'No Supplemental Security Income (Ssi)', type: 'c' },
      {detail: 'With Public Assistance Income', type: 'c' },
      {detail: 'No Public Assistance Income', type: 'c' },
      {detail: 'With Cash Public Assistance Or Food Stamps/Snap', type: 'c' },
      {detail: 'No Cash Public Assistance Or Food Stamps/Snap', type: 'c' },
      {detail: 'With Retirement Income', type: 'c' },
      {detail: 'No Retirement Income', type: 'c' },
    ]
  }
];

const getIcon = (type) => {
  switch(type) {
    case 'p':
      return <PercentIcon sx={{ color: blue[500] }} />;
    case 'd':
      return <AttachMoneyIcon sx={{ color: green[500] }} />;
    case 'c':
      return <NumbersIcon sx={{ color: grey[500] }} />;
    default:
      return type;
  }
};
const IncomeSchema = () => (
  <div className="custom-table-container" style={{background: 'white', color:'black'}}>
    <table className="custom-table">
      <thead>
        <tr>
          <th>Category</th>
          <th>Category Detail</th>
          <th>Estimate</th>
          <th>Error</th>
          <th>Type</th>
        </tr>
      </thead>
      <tbody>
        {data.map((section, index) => (
          <React.Fragment key={index}>
            <tr className="section-row">
              <td rowSpan={section.details.length + 1}>{section.type}</td>
            </tr>
            {section.details.map((detail, detailIndex) => (
              <tr key={detailIndex}>
                <td>{detail.detail}</td>
                <td><CheckIcon sx={{ color: green[500] }} /> </td>
                <td><CheckIcon sx={{ color: green[500] }} /> </td>
                <td>{getIcon(detail.type.toLowerCase())}</td>
                
              </tr>
            ))}
          </React.Fragment>
        ))}
      </tbody>
    </table>
  </div>
);

export default IncomeSchema;
