import React from 'react';
import AuthProvider from 'react-auth-kit';
import RoutesComponent from './RoutesComponent';
import createStore from 'react-auth-kit/createStore';
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import theme from "assets/theme";



function App() {
  const store_type = createStore({
    authName:'_auth',
    authType:'localstorage',
   })
  return (
    <ThemeProvider theme={theme}>
    <AuthProvider store={store_type} >
      <CssBaseline />
      <RoutesComponent/>
      </AuthProvider>
    </ThemeProvider>
  );
}

export default App;


